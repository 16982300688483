<template>
  <v-row
    v-if="itemDetail"
    class="image__footer"
  >
    <div class="first-col">
      <div class="left-content">
        <span class="like-button">
          <v-btn
            v-if="likeClickable"
            icon
            small
            @click.stop.prevent="onClickLikeIcon"
          >
            <v-icon
              v-if="liked"
              class=""
              aria-label="number of views"
              role="img"
              aria-hidden="false"
            >
              mdi-thumb-up
            </v-icon>
            <v-icon
              v-else
              aria-label="number of views"
              role="img"
              aria-hidden="false"
            >
              mdi-thumb-up-outline
            </v-icon>
          </v-btn>
          <v-btn
            v-if="!likeClickable"
            icon
            small
            disabled
            @click.stop.prevent="onClickLikeIcon"
          >
            <v-icon
              v-if="liked"
              class=""
              aria-label="number of views"
              role="img"
              aria-hidden="false"
            >
              mdi-thumb-up
            </v-icon>
            <v-icon
              v-else
              aria-label="number of views"
              role="img"
              aria-hidden="false"
            >
              mdi-thumb-up-outline
            </v-icon>
          </v-btn>
          {{ numberLikes }}
        </span>
        <span>
          <v-icon
            class="material-icons-outlined"
            aria-label="number of views"
            role="img"
            aria-hidden="false"
          >
            remove_red_eye
          </v-icon>
          {{ itemMiscDetail.Views }}
        </span>
      </div>
      <div class="right-content">
        <AdsButton
          icon="bookmarks"
          button-text="Add to collection"
          tertiary
          class="pl-1 pr-2"
          @click="showSaveToCollectionDialog = !showSaveToCollectionDialog"
        />
        <SaveToCollectionDialog
          v-model="showSaveToCollectionDialog"
          :items="[{...itemDetail}]"
          :selected-cards="[{
            ...itemDetail,
            Application: resourceDetails?.audit?.iss
          }]"
        />
      </div>
    </div>
    <div class="second-col">
      <div class="share-button__wrapper">
        <Share
          class="share-button"
          :item="{
            id:itemDetail.ItemID,
            url: resourceUrl,
            name: itemDetail.Name,
            statement: itemDetail.Rights
          }"
        />
      </div>
      <div class="resource-button__wrapper">
        <GoToResources
          :id="itemDetail.ItemID"
          primary
          icon="arrow_drop_down"
          button-text="Go to resources"
          align-right
          :items="resourceList"
          :item-url="resourceUrl"
          :name="itemDetail.Name"
          :source="resourceDetails.record.collection"
        />
      </div>
    </div>
  </v-row>
</template>

<script>
import GoToResources from '@/components/GoToResource/GoToResources';
import Dialog from '@/components/Dialogs/Dialog'
import ReportMenu from '@/views/HubResourceDetailsPage/ResourceDetails/ReportMenu'
import { AdsButton} from '@nswdoe/doe-ui-core';
import SaveToCollectionDialog from '@/components/Dialogs/Collections/SaveToCollection'
import Share from '@/components/Share/ShareMenu'
import {mapGetters, mapState} from 'vuex';
import {combineFilesAndOrderedLinks} from '@/transforms';
import isEqual from 'lodash.isequal';

export default {
  name: 'ImageFooter',
  components: {
    SaveToCollectionDialog,
    Dialog,
    GoToResources,
    ReportMenu,
    Share,
    AdsButton
  },
  data() {
    return {
      reportDialog: false,
      shareToggle: null,
      numberLikes: 0,
      liked: false,
      likeClickable: true,
      showSaveToCollectionDialog: false
    }
  },
  watch: {
    shareToggle() {
      this.shareToggle = undefined;
    },
    async '$route.params.id'(newVal, oldVal) {
      console.log('query update', newVal, oldVal, this.$route.name)
      if (!isEqual(newVal, oldVal) && this.$route.name === 'HubResourceDetailsPage') { // make sure the logic below only applies on this page
        this.liked = this.itemMiscDetail?.LikedBy?.includes(this.userId);
        this.numberLikes = this.itemMiscDetail?.Likes;
      }
    }
  },
  async mounted() {
    setTimeout(() => {
      this.liked = this.itemMiscDetail?.LikedBy?.includes(this.userId);
      this.numberLikes = this.itemMiscDetail?.Likes;
    }, 300);
  },
  computed: {
    ...mapState({ // map the state fields to be used in this component
      itemDetail: state => state.itemDetail,
      resourceDetails: state => state.resourceDetails,
      itemMiscDetail: state => state.itemMiscDetail,
      userId: state => state.uid,
      files: state => state.itemFiles
    }),
    ...mapGetters({
      resourceList: 'goToResourceList'
    }),
    resourceUrl() {
      return (this.resourceDetails?.filelinks[0]?.type === 'file' ? this.resourceDetails?.filelinks[0]?.ZipFileEntryPoint : this.resourceDetails?.filelinks[0]?.url) || '';
    },
    items() {
      let files, links;
      if(this.files) {
        files = [...this.files];
      }
      if(this.resourceDetails?.links) {
        links = [...this.resourceDetails?.links];
      }
      if(files || links) {
        return combineFilesAndOrderedLinks(files, links);
      } else {
        return [];
      }
    }
  },
  methods: {
    async onClickLikeIcon() {
      if (!this.likeClickable) {
        return;
      }

      this.likeClickable = false;
      if (this.liked) {
        this.numberLikes = this.numberLikes - 1;
      } else {
        this.numberLikes = this.numberLikes + 1;
      }
      this.liked = !this.liked;
      let result = await this.$store.dispatch('patchLike', {
        id: this.itemDetail.ItemID,
        name: this.itemDetail.Name,
        like: this.liked,
      });
      if (result.status === 200) {
        this.likeClickable = true;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';

.image__footer {
  margin: 0;

  .first-col {
    padding: 12px 12px 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;

    .left-content {
      display: flex;
      align-items: center;
      min-width: 130px;
      margin-right: 12px;

      span {
        margin-right: 8px;
      }

      .like-button {
        ::v-deep.v-btn {
          &:focus, &:hover {
            outline-color: $ads-navy !important;

            &:before {
              opacity: 0;
            }
          }
        }
      }
    }

    .right-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .icon-container {
        height: 44px;
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.teams-share-button__container {
          padding-top: 6px;
        }
      }
    }
  }

  .second-col {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 12px 0;
    width: fit-content;

    .share-button__wrapper {
      padding-right: 12px;
    }
  }
}

::v-deep.report-button {
  margin-left: 8px;

  &:focus, &:hover {
    outline-color: $ads-red !important;

    &:before {
      opacity: 0;
    }
  }
}

@media only screen and (max-width: 1060px) and (min-width: 960px) {

  .image__footer {
    .first-col {
      padding-right: 0;
    }

    .second-col {
      width: 100%;

      .share-button__wrapper, .resource-button__wrapper {
        flex: 1;
      }
    }
  }
}

@media only screen and (max-width: 730px) {
  .image__footer {
    padding-left: 12px;
    padding-right: 12px;

    .first-col {
      padding-right: 0;
    }

    .second-col {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      padding-top: 0;

      .share-button__wrapper {
        width: 100%;
        padding-right: 0;
        padding-top: 12px;
      }

      .resource-button__wrapper {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 960px) and (min-width: 670px) {

  .image__footer {
    padding-left: 24px;
    padding-right: 24px;
  }
}
</style>
