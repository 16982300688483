<template>
  <Dialog
    v-bind="$attrs"
    icon="mdi-alert-circle-outline"
    title="Changes to search preference"
    :width="$vuetify.breakpoint.xsOnly ? 360 : 800"
    :fullscreen="!$vuetify.breakpoint.smAndUp"
    :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
    v-on="$listeners"
  >
    <template #content>
      <v-container class="new-collection-content">
        <div
          class="ma-3"
          :style="{'color': ADS_Colors.Dark}"
        >
          <p>
            This saved search was created using the {{ aiSearchUser ? 'standard' : 'AI'}} search experience and needs to revert back to use the search criteria.
            You will need to re-enable {{ aiSearchUser ? 'AI' : 'standard'}} search again afterwards should you wish to use this experience
          </p>
        </div>
        <v-divider v-if="!$vuetify.breakpoint.smAndUp" />
        <v-card-actions :class="{'d-flex justify-end px-6 py-6': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}">
          <AdsButton
            secondary
            button-text="Cancel"
            @click="$downwardBroadcast('close:dialog')"
            @keyup.enter="$downwardBroadcast('close:dialog')"
          />
          <AdsButton
            :loading="loading"
            primary
            button-text="Continue"
            @click="confirmPreferenceUpdate"
            @keyup.enter="confirmPreferenceUpdate"
          />
        </v-card-actions>
      </v-container>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog';
import {ADS_Colors, AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'ConfirmSearchPreferenceUpdate',
  components: {
    Dialog,
    AdsButton
  },
  props: {
    collectionId: {
      type: String,
      default: ''
    },
    collectionName: {
      type: String,
      default: ''
    },
    aiSearchUser: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ADS_Colors: ADS_Colors,
      loading: false
    }
  },
  created() {
    console.log(this.collectionName)
  },
  methods: {
    confirmPreferenceUpdate: async function () {
      this.$emit('confirm-preference-update')
      this.$downwardBroadcast('close:dialog');
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../scss/mixins";

.new-collection-content{

  @include respond(phone){

    display: flex;
    flex-flow: column;
    position: relative;
    height: 100%;

    .data-content{
      min-height: 80vh;
    }
    .footer-mobile{
      width: 100%;
    }

  }
}
</style>
