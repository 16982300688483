import Vue from 'vue';
import Vuex from 'vuex';
import api from '../api';

import moduleErrors from './modules/errors';
import moduleUsers from './modules/users';
import moduleSaveSearches from './modules/saveSearches';
import moduleMyResources from './modules/myresources';
import moduleMyCollections from './modules/mycollections';
import moduleUnsplash from './modules/unsplash';
import moduleTutorials from './modules/tutorials';
import moduleMetadata from '@/store/modules/metadata';
import moduleEditor from '@/store/modules/editor';
import moduleAdmin from '@/store/modules/admin';

import {apiBaseUrl, siteBaseUrl, searchBaseUrl} from './config';
import {HUB_FILTERS_STRUCTURE, RESOURCE_SCOPES} from '@/constants';

import {IRecord, SearchApi, SearchApiFilter, SearchApiRequestConfig, SearchQueryParams, SearchResponse, SearchSortItem, SortDirection, SortField} from 'resource_store_vue_client';
import axios from 'axios';
import {combineFilesAndOrderedLinks} from '@/transforms';

const CONFIG = (window as any).dlrhConfig;

export function generateQueriesFromFilter(filters) {
  const queries: string[] = [];
  if (filters.myResourcesLibraries) {
    const libraries = filters.myResourcesLibraries.split(',').join(':');
    queries.push(`action=${libraries}`);
  }
  if (filters['resource-years']) {
    queries.push(`resource-years=${filters['resource-years']}`);
  }
  if (filters.years) { // MyResource page
    queries.push(`years=${filters.years}`);
  }
  if (filters['resource-klas']) {
    queries.push(`resource-klas=${filters['resource-klas']}`);
  }
  if (filters.klas) {  // MyResource page
    queries.push(`klas=${filters.klas}`);
  }
  if (filters['resource-types']) {
    queries.push(`resource-types=${filters['resource-types']}`);
  }
  if (filters.types) {  // MyResource page
    queries.push(`types=${filters.types}`);
  }
  if (filters['resource-sources']) {
    queries.push(`resource-sources=${filters['resource-sources']}`);
  }
  if (filters.formats) { // MyResource page & Hub page
    queries.push(`formats=${filters.formats}`);
  }
  if (filters['resource-randntopics']) {
    if (filters['resource-sources'].includes('readingandnumeracy')) {
      queries.push(`resource-randntopics=${filters['resource-randntopics']}`);
    }
  }
  if (filters.resourcetype) {  // R4, Hub-Type, checkboxHubType
    queries.push(`resourcetype=${filters.resourcetype}`);
  }
  if (filters.phase) {  // R4
    queries.push(`phase=${filters.phase}`);
  }
  if (filters.sefalignment) {  // R4
    queries.push(`sefalignment=${filters.sefalignment}`);
  }
  if (filters.focusarea) {  // R4
    queries.push(`focusarea=${filters.focusarea}`);
  }

  if (queries.length > 0) {
    return queries.join('&'); // array to string
  } else {
    return '';
  }
}

export function getFilterQueryArray(filters): SearchApiFilter[] {
  const filtersQueryArray: SearchApiFilter[] = [];
  // if (filters.myResourcesLibraries) {
  //   const libraries = filters.myResourcesLibraries.split(',').join(':');
  //   queries.push(`action=${libraries}`);
  // }
  if (filters['resource-category']) {  // R5
    filtersQueryArray.push(
      {
        field: SearchQueryParams.ResourceCategory,
        values: filters['resource-category']
      }
    );
  }
  if (filters['resource-years']) {
    filtersQueryArray.push(
      {
        field: SearchQueryParams.Year,
        values: filters['resource-years']
      }
    );
  }
  if (filters.years) { // MyResource page
    filtersQueryArray.push(
      {
        field: SearchQueryParams.Year,
        values: filters.years
      }
    );
  }
  if (filters['resource-klas']) {
    filtersQueryArray.push(
      {
        field: SearchQueryParams.KLA,
        values: filters['resource-klas']
      }
    );
  }
  if (filters.klas) {  // MyResource page
    filtersQueryArray.push(
      {
        field: SearchQueryParams.KLA,
        values: filters.klas
      }
    );
  }
  if (filters['resource-types']) {
    filtersQueryArray.push(
      {
        field: SearchQueryParams.ResourceType,
        values: filters['resource-types']
      }
    );
  }
  /*  if (filters.types) {  // MyResource page
      queries.push(`types=${filters.types}`);
    }*/
  if (filters['resource-sources']) {
    filtersQueryArray.push(
      {
        field: SearchQueryParams.Source,
        values: filters['resource-sources']
      }
    );
  }
  if (filters.formats) { // MyResource page & Hub page
    filtersQueryArray.push(
      {
        field: SearchQueryParams.Format,
        values: filters.formats
      }
    );
  }
  if (filters['resource-randntopics']) {
    if (filters['resource-sources'].includes('readingandnumeracy') || filters['resource-sources'].includes('literacyandnumeracypackages')) {
      filtersQueryArray.push(
        {
          field: SearchQueryParams.RandNTopic,
          values: filters['resource-randntopics']
        }
      );
    }
  }
  if (filters.resourcetype) {  // R4, Hub-Type, checkboxHubType
    filtersQueryArray.push(
      {
        field: SearchQueryParams.ResourceType,
        values: filters.resourcetype
      }
    );
  }
  if (filters.phase) {  // R4
    filtersQueryArray.push(
      {
        field: SearchQueryParams.Phase,
        values: filters.phase
      }
    );
  }
  if (filters.sefalignment) {  // R4
    filtersQueryArray.push(
      {
        field: SearchQueryParams.SEFAlignment,
        values: filters.sefalignment
      }
    );
  }
  if (filters.focusarea) {  // R4
    filtersQueryArray.push(
      {
        field: SearchQueryParams.FocusArea,
        values: filters.focusarea
      }
    );
  }
  if (filters.urhlibrary === 'collections') {  // R4.4
    filtersQueryArray.push(
      {
        field: 'record.isResourceCollection',
        values: ['true']
      }
    );
  }
  if (filters.urhlibrary === 'urh') {  // R4.4
    filtersQueryArray.push(
      {
        field: 'record.isResourceCollection',
        values: ['false']
      }
    );
  }

  return filtersQueryArray;
}

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    errors: moduleErrors,
    users: moduleUsers,
    saveSearches: moduleSaveSearches,
    myResources: moduleMyResources,
    myCollections: moduleMyCollections,
    unsplash: moduleUnsplash,
    tutorials: moduleTutorials,
    metadata: moduleMetadata,
    editor: moduleEditor,
    admin: moduleAdmin
  },
  state: {
    // add GET header to Vuex for reactivity
    allowAccess: false,
    uid: '',
    schoolCodes: '',
    email: '',
    roles: '',
    sessionId: '',
    isDirectAccess: false,
    searchContext: '',
    isLoading: false,
    loadPercent: 0,
    dialogActive: false,
    userScroll: false,
    collectionView: false,
    resources: {
      items: [],
      metadata: {}
    },
    resourceDetails: null,
    itemDetail: null,
    itemFiles: null,
    itemMiscDetail: {},
    metadata: null,
    searchRef: '', // for tracking external traffic
    // below are URL query params, all in Vuex state, all have mutations
    perPage: 16,
    pageNum: 1,
    sort: {
      field: SortField.RELEVANCE
    } as SearchSortItem, // default sorting changes from updated:desc to numberViews:desc for 3.0
    aiInput: '',
    keywords: '',
    searchResultsCount: null,
    filters: { // for search API calls in Vuex action, used to generate search object, v1: filters -> queries, v2: filters -> search object
      urhlibrary: '',
      library: '',
      'resource-category': '',
      'resource-years': '',
      'resource-klas': '',
      'resource-types': '',
      'resource-sources': '',
      'resource-randntopics': '',
      // new query parameters in R4 for Hub:
      formats: '',
      resourcetype: '', // Hub-Type, checkboxHubType
      phase: '',
      sefalignment: '',
      focusarea: ''
    },
    filtersSelection: { // filters selection by the user, source of v-model on <ChipFilters>.
      checkboxResourceCategory: [],
      checkboxUrhLibrary: [],
      checkboxLibrary: [],
      checkboxYears: [],
      checkboxKLAs: [],
      checkboxResourceType: [],
      checkboxSource: [],
      checkboxRandN: [],
      // new in R4:
      checkboxFormat: [],
      checkboxHubType: [],
      checkboxPhase: [],
      checkboxSefAlignment: [],
      checkboxFocusArea: []
    },
    resourceCategory: [],  // R4, resource category used in preferences & search scope. Not part of the filters

    searchMode: false,
    preferencesMode: false,

    searchParametersForSave: '',
    showRandNFilter: false,
    lastSearch: '',
    sendingEmail: false,
    previousPath: '',

    // UPS config
    klas: [],
    library: [],
    resourceTypes: [],
    maxNumCollections: 0,
    maxNumSaveSearch: 0,
    randntopics: [],
    sources: [],
    type: [],
    years: [],
    focusarea: [],
    sefAlignment: [],
    phases: [],
    format: [],
    _id: '',

    alert: {},
    displayAlert: false,
    hitBottomCount: 0,
    clearFiltersSelection: false,
    scope: RESOURCE_SCOPES.INTERNAL_SCOPE,
  } as any,
  getters: {
    displayAlert: state => state.displayAlert,
    alert: state => state.alert,
    filters: state => state.filters,
    isFiltersEmpty: state => {
      for (const filter in state.filters) {
        if (state.filters[filter] !== '') {
          return false;
        }
      }
      return true;
    },
    resources: state => state.resources,
    sendingEmail: state => state.sendingEmail,
    stages: state => {
      let _stages: string[] = [];
      if (state.itemDetail && state.itemDetail.Year) {
        state.itemDetail.Year.forEach(year => {
          switch (year) {
            case 'P':
              _stages.push('Preschool');
              break;
            case 'K':
              _stages.push('ES1');
              break;
            case '1':
            case '2':
              _stages.push('1');
              break;
            case '3':
            case '4':
              _stages.push('2');
              break;
            case '5':
            case '6':
              _stages.push('3');
              break;
            case '7':
            case '8':
              _stages.push('4');
              break;
            case '9':
            case '10':
              _stages.push('5');
              break;
            case '11':
            case '12':
              _stages.push('6');
              break;
            default:
              break;
          }
        });
        _stages = [...new Set(_stages)]
        return _stages;
      }
    },
    searchParametersForSave: state => state.searchParametersForSave,
    resourceCategory: state => state.resourceCategory,
    isSearchScopeChanged: state => {
      return state.resourceCategory?.join(',') !== state['users/preferences']?.resourceCategory?.join(',');
    },
    hitBottomCount: state => state.hitBottomCount,
    scope: state => state.scope,
    goToResourceList: state => {
      let files: any[] = [], links;
      // 1. files
      state.resourceDetails?.files?.forEach(f => {
        if (!f.Hidden) {
          files.push({
            FileName: f.FileName,
            DownloadUrl: f.DownloadUrl,
            ZipFileEntryPoint: f.ZipFileEntryPoint
          })
          // if ZipFileEntryPoint === DownloadUrl, it means there's not a different entry point, and UI only displays the download button
          // otherwise if ZipFileEntryPoint !== DownloadUrl, there is a different entry point to launch and open in a new tab, and UI displays the download button + the launch button

          // for a scorm zip (to skip containsFiles), DownloadUrl and ZipFileEntryPoint are different
          if (f.containsFiles?.length > 0 && f.ArchiveType !== 'SCORM') { // not a scorm zip
            files = files.concat(f.containsFiles).filter(f => !f.Hidden);
          }
        }
      })
      // 2. links
      if (state.resourceDetails?.links) {
        links = [...state.resourceDetails?.links];
      }
      if (files || links) {
        const res = combineFilesAndOrderedLinks(files, links);
        console.log('res = ', res);
        return res;
      } else {
        return [];
      }
    },
  },
  mutations: {
    SET_HIT_BOTTOM_COUNT(state) {
      state.hitBottomCount = state.hitBottomCount + 1;
    },
    SET_FILTERS_SELECTION(state, value) {
      state.filtersSelection = value;
    },
    SET_CLEAR_FILTERS_SELECTION(state, value) {
      state.clearFiltersSelection = value;
    },
    SET_RESOURCE_CATEGORY(state, category) {
      state.resourceCategory = category;
    },
    SET_UID(state, uid) {
      state.uid = uid;
    },
    SET_SCHOOL_CODES(state, schoolCodes) {
      state.schoolCodes = schoolCodes;
    },
    SET_EMAIL(state, email) {
      state.email = email;
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_SESSION_ID(state, sessionId) {
      state.sessionId = sessionId;
    },
    SET_IS_DIRECT_ACCESS(state, isDirectAccess) {
      state.isDirectAccess = isDirectAccess;
    },
    SET_COLLECTION_VIEW(state, isCollectionView) {
      state.collectionView = isCollectionView;
    },
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
      if(state.isLoading == false) state.loadPercent = 0; // if it's not loading anymore, the load percent shouldn't stay higher than 0.
    },
    SET_LOAD_PERCENT(state, loadPercent) {
        state.loadPercent = loadPercent;
    },
    SET_DIALOG_ACTIVE(state, isActive) {
      state.dialogActive = isActive;
    },
    SET_ALLOW_ACCESS(state, value) {
      state.allowAccess = value;
    },
    SET_USER_SCROLL(state) {
      state.userScroll = true;
    },
    SET_DISPLAY_ALERT(state, value) {
      state.displayAlert = value;
      if (!value)
        state.alert = {}
    },
    SET_ALERT(state, value) {
      state.alert = value;
    },
    SET_RESOURCES(state, {items, metadata}) {
      state.resources.metadata = metadata;
      if (state.userScroll) {
        state.resources.items = state.resources.items.concat(items);
      } else {
        state.resources.items = items;
      }
    },
    SET_PAGE_NUM(state, pageNum) {
      state.pageNum = pageNum;
    },
    SET_SORT(state, sortSearchObj) {
      state.sort = sortSearchObj;
    },
    SET_AI_INPUT(state, aiInput) {
      state.aiInput = aiInput;
    },
    SET_KEYWORDS(state, keywords) {
      state.keywords = keywords;
    },
    SET_SEARCH_REFERENCE(state, ref) {
      state.searchRef = ref;
    },
    SET_SEARCH_CONTEXT(state, context) {
      state.searchContext = context;
    },
    SET_SEARCH_MODE(state, searchMode) {
      state.searchMode = searchMode;
      if (!searchMode) {
        state.lastSearch = '';
        // reset search
        state.resources.metadata = {};
        state.resources.items = [];
        state.pageNum = 1;
        state.userScroll = false;
      }
      state.preferencesMode = false;
    },
    SET_PREFERENCES_MODE(state, preferencesMode) {
      state.preferencesMode = preferencesMode;
    },
    CLEAR_SEARCH(state) {
      state.resources.metadata = {};
      state.resources.items = [];
      state.pageNum = 1;
      state.userScroll = false;
    },
    SET_FILTERS(state, filters) {
      state.filters = Object.assign(state.filters, filters);
    },
    CLEAR_ALL_FILTERS(state) {
      state.filters = {...HUB_FILTERS_STRUCTURE};
      state.filtersSelection = {
        checkboxResourceCategory: [],
        checkboxUrhLibrary: [],
        checkboxFocusArea: [],
        checkboxFormat: [],
        checkboxHubType: [],
        checkboxKLAs: [],
        checkboxLibrary: [],
        checkboxPhase: [],
        checkboxRandN: [],
        checkboxResourceType: [],
        checkboxSefAlignment: [],
        checkboxSource: [],
        checkboxYears: []
      }
    },
    SET_SEARCH_PARAMETERS_FOR_SAVE(state, searchParametersForSave) {
      state.searchParametersForSave = searchParametersForSave;
    },
    SET_RESOURCE_DETAILS(state, resource) {
      state.resourceDetails = resource;
    },
    SET_ITEM_DETAIL(state, itemDetail) {
      state.itemDetail = itemDetail;
    },
    SET_ITEM_MISC_DETAIL(state, miscDetail) {
      state.itemMiscDetail = miscDetail;
    },
    SET_ITEM_FILES(state, files) {
      state.itemFiles = files
    },
    SET_SEARCH_RESULTS_COUNT(state, count) {
      state.searchResultsCount = count;
    },
    SET_SHOW_RANDN_FILTER(state, value) {
      state.showRandNFilter = value;
    },
    SET_LAST_SEARCH(state, value) {
      state.lastSearch = value;
    },
    SET_SENDING_EMAIL(state, value) {
      state.sendingEmail = value;
    },
    SET_PREVIOUS_PATH(state, value) {
      state.previousPath = value;
    },
    SET_SCOPE(state, value) {
      state.scope = value;
    }
  },
  actions: {
    patchLike({state}, {id, name, like}) {
      const url = `${searchBaseUrl}/urhsearch/${id}?action=like`;
      const config = {
        headers:
          {
            'Content-Type': 'application/json',
            'userId': state.uid,
            'school_codes': state.schoolCodes,
            'email': state.email,
            'sessionId': state.sessionId,
            'resourcetitle': name,
            'liked': like
          }
      };

      return api.resources.patchResource(url, undefined, config);
    },
    patchViews({state}, id) {
      const url = `${searchBaseUrl}/urhsearch/${id}?action=views`;
      const config = {
        headers:
          {
            'Content-Type': 'application/json',
            'userId': state.uid,
            'school_codes': state.schoolCodes,
            'email': state.email,
            'sessionId': state.sessionId
          }
      };

      return api.resources.patchResource(url, undefined, config);
    },
    captureGoogleClassroomShare({state}, {id, name}) {
      const url = `${searchBaseUrl}/urhsearch/${id}?action=classroom`;
      const config = {
        headers:
          {
            'Content-Type': 'application/json',
            'userId': state.uid,
            'school_codes': state.schoolCodes,
            'email': state.email,
            'sessionId': state.sessionId,
            'resourcetitle': name,
          }
      };

      api.resources.patchResource(url, undefined, config)
        .then(() => {
        })
        .catch(error => console.error(error));
    },
    captureTeamsShare({state}, {id, name}) {
      const url = `${searchBaseUrl}/urhsearch/${id}?action=teams`;
      const config = {
        headers:
          {
            'Content-Type': 'application/json',
            'userId': state.uid,
            'school_codes': state.schoolCodes,
            'email': state.email,
            'sessionId': state.sessionId,
            'resourcetitle': name,
          }
      };

      api.resources.patchResource(url, undefined, config)
        .then(() => {
        })
        .catch(error => console.error(error));
    },
    captureGoToResource({state}, {id, name, url, contentArea}) {
      const endpoint = `${searchBaseUrl}/urhsearch/${id}?action=gotoresource`;
      const config = {
        headers:
          {
            'Content-Type': 'application/json',
            'userId': state.uid,
            'school_codes': Array.isArray(state.schoolCodes) && state.schoolCodes.join(', ') || state.schoolCodes,
            'roles': Array.isArray(state.roles) && state.roles.join(', ') || state.roles,
            'email': state.email,
            'sessionId': state.sessionId,
            'resourcetitle': name,
            'url': url,
            'contentarea': contentArea
          }
      };

      api.resources.patchResource(endpoint, undefined, config)
        .then(() => {
        })
        .catch(error => console.error(error));
    },
    async fetchResourcesForHomePageWithClient({commit, dispatch, state}, {library, filters, sort, isHomePage}): Promise<IRecord[] | void> { // payload is from getResourcesForHome()
      if (state.searchMode) {
        return
      }
      // check isLoading
      if (!state.isLoading) {
        commit('SET_IS_LOADING', true);
      }

      // prepare values for SearchApiRequestConfig object
      let _library;
      if (library) {
        _library = library;
      } else {
        _library = 'dlrh';
      }
      const _sort = sort ? sort : state.sort;

      let resourcesPerPage = 8;

      if (state.resources.items.length === 0 && window.innerWidth > 1920) {
        resourcesPerPage = 48;
      }

      const searchApiRequestConfig: SearchApiRequestConfig = {
        application: {
          application: CONFIG.APPLICATION,
          source: _library,
          version: '2',
          uid: state.uid,
          authorization: Vue.prototype.$OAuth.buildHttpHeader().Authorization
        },
        sort: _sort, // object or null
        filters: filters, // object or null
        options: {
          size: resourcesPerPage,
          page: state.pageNum,
          noArchive: true,
          noCollections: true
        },
        standardFieldFilters: { // fetch only published resources
          // published: [true]
          status: [5]
        },
        apiEnv: CONFIG.envUI,
        isHomePage: isHomePage
      };

      if (state.preferencesMode) { // preferencesMode is for the first 3 rows in seeAllFromHomePage().
        const filtersQueryArray: SearchApiFilter[] = [];

        // based on users.preferences, construct queries for URL
        if (state.users.userProfile.onBoarded) {
          const klas = state.users.preferences ? state.users.preferences.learningAreas.join(',') : '';
          if (klas) {
            filtersQueryArray.push(
              {
                field: SearchQueryParams.KLA,
                values: klas
              }
            );
          }
          const years = state.users.preferences ? state.users.preferences.years.join(',') : '';
          if (years) {
            filtersQueryArray.push(
              {
                field: SearchQueryParams.Year,
                values: years
              }
            );
          }
          const siSources = state.users.preferences ? state.users.preferences.schoolImprovementSources.join(',') : '';
          if (siSources) {
            filtersQueryArray.push(
              {
                field: SearchQueryParams.Source,
                values: siSources
              }
            );
          }
        }
      }

      try {
        console.log(searchApiRequestConfig)
        const response: SearchResponse = await SearchApi.search(searchApiRequestConfig);
        const items: IRecord[] = response.resources;
        const metadata = response.meta;
        // if (!payload) {
        commit('SET_RESOURCES', {items, metadata});
        if (state.pageNum === 1) {
          commit('SET_SEARCH_RESULTS_COUNT', metadata.count);
        }
        // }
        setTimeout(() => {
          if (state.isLoading) {
            commit('SET_IS_LOADING', false);
          }
        }, 200);
        dispatch('setScope', items?.[0]?.userAccess?.scope);
        return items;
      } catch (error) {
        console.error(error);
      }
    },
    logForSearching({state, commit}, body) {
      const semanticSearch = state.users.userProfile?.preferences?.showNewSearch || false
      const url = `${apiBaseUrl}/writelog?semantic=${semanticSearch}`;
      const schoolCodes = Array.isArray(state.schoolCodes) && state.schoolCodes.join(', ') || state.schoolCodes;
      const roles = Array.isArray(state.roles) && state.roles.join(', ') || state.roles;
      const config = {
        headers:
          {
            'Content-Type': 'application/json',
            'userId': state.uid,
            'school_codes': schoolCodes,
            'email': state.email,
            'roles': roles,
            'sessionId': state.sessionId,
          }
      };
      // cleanup tracking reference after first use
      commit('SET_SEARCH_REFERENCE', '');
      return axios.post(url, body, config);
    },
    async fetchResourcesWithClientAI({commit, state}): Promise<IRecord[] | void> {
      console.log('>>>>>>>> performing AI search....')
      // check isLoading
      if (!state.isLoading) {
        commit('SET_IS_LOADING', true);
      }

      let resourcesPerPage = 16;
      if (state.preferencesMode || state.searchMode) {
        resourcesPerPage = 16;
        if (state.resources.items.length === 0 && window.innerWidth > 1920) {
          resourcesPerPage = 48;
        }
      }

      (window as any).dataLayer.push({
        'event': 'resourceSearch',
        'filters': [],
        'keyword': state.aiInput
      });

      const searchApiRequestConfig: SearchApiRequestConfig = {
        application: {
          application: CONFIG.APPLICATION,
          source: 'dlrh',
          version: '2',
          uid: state.uid,
          authorization: Vue.prototype.$OAuth.buildHttpHeader().Authorization
        },
        keyword: state.aiInput,
        sort: state.sort,
        filters: [],
        standardFieldFilters: { // fetch only published resources
          status: [5]
        },
        options: {
          size: resourcesPerPage,
          page: state.pageNum,
          noArchive: true,
          noCollections: true,
          semantic: true
        },
        apiEnv: CONFIG.envUI,
      };

      console.log('>>>>>>>>>>> search config object', searchApiRequestConfig)
      try {
        const response: SearchResponse = await SearchApi.search(searchApiRequestConfig);
        const items: IRecord[] = response.resources;
        const metadata = response.meta;
        commit('SET_RESOURCES', {items, metadata});
        if (state.pageNum === 1) {
          commit('SET_SEARCH_RESULTS_COUNT', metadata.count);
        }

        const trackingRef = state.searchRef;
        // logging
        const body = {
          meta: metadata,
          ...(trackingRef && { // add tracking reference from direct url
            ref: trackingRef
          }),
          query: {
            application: CONFIG.APPLICATION,
            source: 'dlrh',
            version: '2',
            uid: state.uid,
            keyword: state.aiInput,
            sort: {
              field: state.sort
            },
            filters: [],
            options: {
              size: resourcesPerPage,
              page: state.pageNum
            },
            apiEnv: CONFIG.envUI
          }
        }
        this.dispatch('logForSearching', body);

        setTimeout(() => {
          if (state.isLoading) {
            commit('SET_IS_LOADING', false);
          }
        }, 200);
        return items;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchResourcesWithClient({commit, state}): Promise<IRecord[] | void> {

      const semanticSearch = state.users.userProfile?.preferences?.showNewSearch || false

      if (!(/^[a-zA-Z0-9 '.\-+=&_/"]*$/.test(state.keywords))) {
        return
      }
      if(semanticSearch) {
        return this.dispatch('fetchResourcesWithClientAI');
      }
      // check isLoading
      if (!state.isLoading) {
        commit('SET_IS_LOADING', true);
      }

      let resourcesPerPage = 16;
      if (state.preferencesMode || state.searchMode) {
        resourcesPerPage = 16;
        if (state.resources.items.length === 0 && window.innerWidth > 1920) {
          resourcesPerPage = 48;
        }
      }

      // if there is keywords, add the keywords param to the URL
      let keywords = '';
      if (state.keywords && state.keywords.length >= 3) {
        // handle special characters reserved for query string parameters
        keywords = state.keywords;
        keywords = keywords.replace(/[`~!@#$%^&*+=|<>\\()]/g, '');
        keywords = keywords.replace(' - ', ' _ ');
        keywords = keywords.replace('/', '_');
        console.log(keywords)
      }

      const filtersQueryArray = getFilterQueryArray(state.filters);
      if (filtersQueryArray.length > 0) {
        commit('SET_SEARCH_PARAMETERS_FOR_SAVE', filtersQueryArray);
      }

      (window as any).dataLayer.push({
        'event': 'resourceSearch',
        'filters': filtersQueryArray,
        'keyword': keywords
      });

      const searchApiRequestConfig: SearchApiRequestConfig = {
        application: {
          application: CONFIG.APPLICATION,
          source: 'dlrh',
          version: '2',
          uid: state.uid,
          authorization: Vue.prototype.$OAuth.buildHttpHeader().Authorization
        },
        keyword: keywords,
        sort: state.sort,
        filters: filtersQueryArray,
        standardFieldFilters: { // fetch only published resources
          // published: [true]
          status: [5]
        },
        options: {
          size: resourcesPerPage,
          page: state.pageNum,
          noArchive: true,
          noCollections: true
        },
        apiEnv: CONFIG.envUI
      };

      try {
        const response: SearchResponse = await SearchApi.search(searchApiRequestConfig);
        const items: IRecord[] = response.resources;
        const metadata = response.meta;
        commit('SET_RESOURCES', {items, metadata});
        if (state.pageNum === 1) {
          commit('SET_SEARCH_RESULTS_COUNT', metadata.count);
        }

        const trackingRef = state.searchRef;
        // logging
        const body = {
          meta: metadata,
          ...(trackingRef && { // add tracking reference from direct url
            ref: trackingRef
          }),
          query: {
            application: CONFIG.APPLICATION,
            source: 'dlrh',
            version: '2',
            uid: state.uid,
            keyword: keywords,
            sort: {
              field: state.sort
            },
            filters: filtersQueryArray,
            options: {
              size: resourcesPerPage,
              page: state.pageNum
            },
            apiEnv: CONFIG.envUI
          }
        }
        this.dispatch('logForSearching', body);

        setTimeout(() => {
          if (state.isLoading) {
            commit('SET_IS_LOADING', false);
          }
        }, 200);
        return items;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchResources({commit, state}) {
      // check isLoading
      if (!state.isLoading) {
        commit('SET_IS_LOADING', true);
      }

      let resourcesURL;

      let resourcesPerPage = 16;
      if (state.preferencesMode || state.searchMode) {
        resourcesPerPage = 16;
        if (state.resources.items.length === 0 && window.innerWidth > 1920) {
          resourcesPerPage = 48;
        }
      }

      resourcesURL = `${apiBaseUrl}/resourcesschemaalignment?per-page=${resourcesPerPage}&page-num=${state.pageNum}`;
      // if there is keywords, add the keywords param to the URL
      if (state.keywords && state.keywords.length >= 3) {
        // handle special characters reserved for query string parameters
        let keywords = state.keywords;
        keywords = keywords.replace(/[`~!@#$%^&*+=|<>/\\()]/g, '');
        keywords = keywords.replace(' - ', ' _ ');
        resourcesURL = `${resourcesURL}&keywords=${keywords}`;
      }
      // always generate queries from Filter, if no filter, it is also fine
      const queries = generateQueriesFromFilter(state.filters);
      if (queries) {
        resourcesURL = `${resourcesURL}&${queries}`;
        commit('SET_SEARCH_PARAMETERS_FOR_SAVE', queries);
      }

      // append sort and resource category
      resourcesURL = `${resourcesURL}&sort=${state.sort}`;

      const resourceCategory = state.resourceCategory.join(',')
      // if length is 0 or more than one we dont need this query
      if (resourceCategory !== '') {
        resourcesURL += `&resource-categories=${resourceCategory}`;
      }

      if (state.lastSearch === resourcesURL) {
        commit('SET_IS_LOADING', false);
        return;
      } else {
        commit('SET_LAST_SEARCH', resourcesURL);
      }

      let config;
      const schoolCodes = Array.isArray(state.schoolCodes) && state.schoolCodes.join(', ') || state.schoolCodes;
      const roles = Array.isArray(state.roles) && state.roles.join(', ') || state.roles;
      if (state.isDirectAccess) {
        config = {
          headers:
            {
              'Content-Type': 'application/json',
              'userId': state.uid,
              'school_codes': schoolCodes,
              'email': state.email,
              'roles': roles,
              'sessionId': state.sessionId,
              'directaccess': 'readingandnumeracy',
            }
        };
      } else {
        config = {
          headers:
            {
              'Content-Type': 'application/json',
              'userId': state.uid,
              'school_codes': schoolCodes,
              'email': state.email,
              'roles': roles,
              'sessionId': state.sessionId,
            }
        };
      }
      const response = await api.resources.getResources(resourcesURL, config);
      const items = response.data.items;
      const metadata = response.data.metadata;
      commit('SET_RESOURCES', {items, metadata});
      if (state.pageNum === 1) {
        commit('SET_SEARCH_RESULTS_COUNT', metadata.count);
      }
      setTimeout(() => {
        if (state.isLoading) {
          commit('SET_IS_LOADING', false);
        }
      }, 200);
      return response.data;
    },
    async fetchResourcesForMostViewedWithClient({commit, state}) {
      // check isLoading
      if (!state.isLoading) {
        commit('SET_IS_LOADING', true);
      }

      const searchApiRequestConfig: SearchApiRequestConfig = {
        application: {
          application: CONFIG.APPLICATION,
          source: 'dlrh',
          version: '2',
          uid: state.uid,
          authorization: Vue.prototype.$OAuth.buildHttpHeader().Authorization
        },
        sort: {
          field: SortField.NUMBER_VIEWS,
          direction: SortDirection.DESC
        },
        options: {
          size: 4,
          page: 1,
          noArchive: true,
          noCollections: true
        },
        standardFieldFilters: { // fetch only published resources
          // published: [true]
          status: [5]
        },
        apiEnv: CONFIG.envUI
      };

      try {
        const response: SearchResponse = await SearchApi.search(searchApiRequestConfig);
        setTimeout(() => {
          if (state.isLoading) {
            commit('SET_IS_LOADING', false);
          }
        }, 200);
        return response.resources;
      } catch (error) {
        console.error(error);
      }
    },
// for Most Viewed side column on HubResourceDetailsPage, not related to store.state
    async fetchResourcesForMostViewed({commit, state}) {
      // check isLoading
      if (!state.isLoading) {
        commit('SET_IS_LOADING', true);
      }
      const resourcesURL = `${apiBaseUrl}/resourcesschemaalignment?per-page=4&page-num=1&sort=numberViews:desc`;
      const schoolCodes = Array.isArray(state.schoolCodes) && state.schoolCodes.join(', ') || state.schoolCodes;
      const roles = Array.isArray(state.roles) && state.roles.join(', ') || state.roles;
      const config = {
        headers:
          {
            'Content-Type': 'application/json',
            'userId': state.uid,
            'school_codes': schoolCodes,
            'email': state.email,
            'roles': roles,
            'sessionId': state.sessionId,
          }
      };
      const response = await api.resources.getResources(resourcesURL, config);
      setTimeout(() => {
        if (state.isLoading) {
          commit('SET_IS_LOADING', false);
        }
      }, 200);
      return response.data;
    },
    logForViewing({state}, {itemId, body}) {
      const url = `${apiBaseUrl}/writelog/${itemId}`;
      const schoolCodes = Array.isArray(state.schoolCodes) && state.schoolCodes.join(', ') || state.schoolCodes;
      const roles = Array.isArray(state.roles) && state.roles.join(', ') || state.roles;
      let config;
      if (state.isDirectAccess) {
        config = {
          headers:
            {
              'Content-Type': 'application/json',
              'userId': state.uid,
              'school_codes': schoolCodes,
              'email': state.email,
              'roles': roles,
              'sessionId': state.sessionId,
              'directaccess': 'readingandnumeracy',
            }
        };
      } else {
        config = {
          headers:
            {
              'Content-Type': 'application/json',
              'userId': state.uid,
              'school_codes': schoolCodes,
              'email': state.email,
              'roles': roles,
              'sessionId': state.sessionId,
            }
        };
      }

      return axios.post(url, body, config);
    },
    async fetchResourceDetailsWithClient({commit, state, dispatch}, id) {
      const searchApiRequestConfig: SearchApiRequestConfig = {
        application: {
          application: CONFIG.APPLICATION,
          source: 'dlrh',
          version: '2',
          uid: state.uid,
          authorization: Vue.prototype.$OAuth.buildHttpHeader().Authorization
        },
        options: {
          noArchive: true,
          noCollections: true
        },
        itemId: id,
        apiEnv: CONFIG.envUI
      };

      try {
        const response: SearchResponse = await SearchApi.search(searchApiRequestConfig);
        const items: IRecord[] = response.resources;
        let itemDetail = items[0].document;
        if (!itemDetail) {
          itemDetail = null;
        }
        commit('SET_ITEM_DETAIL', itemDetail);
        commit('SET_ITEM_MISC_DETAIL', items[0]?.misc);
        commit('SET_ITEM_FILES', items[0]?.files);
        const resource = response.resources[0];
        commit('SET_RESOURCE_DETAILS', resource);

        // logging
        const responseCopy = {...response} as any;
        const sanitizedBody = {
          meta: responseCopy.meta,
          resources: responseCopy.resources.map(r => {
            return {
              audit: r.audit,
              record: r.record,
              document: r.document
            }
          })
        }
        this.dispatch('logForViewing', {itemId: items[0].record.id, body: sanitizedBody})

        return itemDetail;
      } catch (error) {
        console.error(error);
      }
    },
    fetchDetailCard({commit, state}, id) {
      const url = `${apiBaseUrl}/resourcesschemaalignment/${id}`;

      const config = {
        headers:
          {
            'Content-Type': 'application/json',
            'userId': state.uid,
            'school_codes': Array.isArray(state.schoolCodes) && state.schoolCodes.join(', ') || state.schoolCodes,
            'email': state.email,
            'roles': Array.isArray(state.roles) && state.roles.join(', ') || state.roles,
            'sessionId': state.sessionId,
          }
      };

      return api.resources.getDetailCard(url, config)
        .then(response => {
          let itemDetail = response.data;
          if (itemDetail.relatedResources) {
            itemDetail.relatedResources.forEach(r => {
              // API data returned is "{envURL}/detail/PA-02"
              if (r.url.includes('{envURL}')) {
                r.url = r.url.replace('{envURL}/', siteBaseUrl);
              }
            });
          }
          if (!itemDetail) {
            itemDetail = null;
          }
          commit('SET_ITEM_DETAIL', itemDetail);
          return itemDetail;
        })
        .catch(error => console.error(error));
    },
    sendResourceByEmail({state, commit}, data) {
      commit('SET_SENDING_EMAIL', true);
      const url = `${apiBaseUrl}/sendemail`

      const config = {
        headers: {
          ...Vue.prototype.$OAuth.buildHttpHeader({
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }),
        }
      };
      return api.resources.sendEmail(url, data, config)
        .finally(() => {
          commit('SET_SENDING_EMAIL', false);
        });
    },
    setScope({commit}, scope) {
      if (scope !== undefined) {
        commit('SET_SCOPE', scope);
      }
    },
  }
})

