<template>
  <div class="search-params__wrapper">
    <h2
      v-if="!isCard"
      class="subtitle"
    >
      Search parameters
    </h2>
    <div v-if="keywords || aiInput">
      <h3 class="sub-subtitle">
        Search keywords
      </h3>
      <p :class="{ paragraph: true, 'paragraph-card__keywords': isCard }">
        {{ keywords || aiInput }}
      </p>
    </div>
    <div v-if="allParameters.length > 0">
      <h3 class="sub-subtitle">
        Search filters
      </h3>
      <p :class="{ 'paragraph search-params': true, 'paragraph-card__filters': isCard }">
        <span
          v-for="(item, i) in allParameters"
          :key="i"
        >
          <span>{{ item }}</span>
          <span v-if="i < allParameters.length - 1">&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</span>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchParameters',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isCard: {
      type: Boolean,
      default: false
    },
    searchParamsObj: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formattedFiltersParams: []
    };
  },
  computed: {
    keywords() {
      return this.searchParamsObj.keywords || ''
    },
    aiInput() {
      return this.searchParamsObj['ai-input'] || ''
    },
    allParameters() {
      return this.generateParameters(
        this.searchParamsObj
      );
    }
  },
  watch: {
    allParameters() {
      this.$emit('formattedFilterParams', this.allParameters);
    }
  },
  methods: {
    getAllChildFilters(metadata) {
      return this.$store.getters[metadata]?.reduce(function(previousValue, currentValue) {
        return [...previousValue, ...currentValue.children]
      }, [{}])
    },
    formatSearchParams(metadata, currentSelection) {
      return currentSelection
        .map(i => {
          const itemData = metadata.find(e => e.id === i);
          return itemData?.name;
        })
        .join(', ');
    },
    generateParameters(obj) {
      let formattedFiltersParams = [];
      if (obj) {
        Object.entries(obj).forEach(([key, value]) => {
          if (value) {
            value = value.split(',');
            switch (key) {
              case 'resource-category':
                formattedFiltersParams.push(
                  this.formatSearchParams(
                    this.$store.getters['metadata/resourcecategories'],
                    value
                  )
                );
                break;
              case 'resource-years':
                formattedFiltersParams.push(
                  this.formatSearchParams(
                    this.$store.getters['metadata/years'],
                    value
                  )
                );
                break;
              case 'resource-klas':
                formattedFiltersParams.push(
                  this.formatSearchParams(
                    this.$store.getters['metadata/klas'],
                    value
                  )
                );
                break;
              case 'resource-types':
                formattedFiltersParams.push(
                  this.formatSearchParams(
                    this.$store.getters['metadata/hubFormat'],
                    value
                  )
                );
                break;
              case 'resource-sources':
                formattedFiltersParams.push(
                  this.formatSearchParams(
                    this.$store.getters['metadata/sources'],
                    value
                  )
                );
                break;
              case 'resource-randntopics':
                formattedFiltersParams.push(
                  this.formatSearchParams(
                    this.$store.getters['metadata/randntopics'],
                    value
                  )
                );
                break;
              case 'resourcetype':
                formattedFiltersParams.push(
                  this.formatSearchParams(
                    this.getAllChildFilters('metadata/hubTypes'),
                    value
                  )
                );
                break;
              case 'sefalignment':
                formattedFiltersParams.push(
                  this.formatSearchParams(
                    this.$store.getters['metadata/sefAlignmentV3Themes'],
                    value
                  )
                );
                break;
              case 'phase':
                formattedFiltersParams.push(
                  this.formatSearchParams(
                    this.getAllChildFilters('metadata/phases'),
                    value
                  )
                );
                break;
              case 'focusarea':
                formattedFiltersParams.push(
                  this.formatSearchParams(
                    this.getAllChildFilters('metadata/focusArea'),
                    value
                  )
                );
                break;
              case 'randntopics':
                formattedFiltersParams.push(
                  this.formatSearchParams(
                    this.$store.getters['metadata/rnrtopics'],
                    value
                  )
                );
                break;
            }
          }
        });
      }
      return formattedFiltersParams;
    }
  }
};
</script>

<style lang="scss" scoped>
.search-params__wrapper {
  color: #000;
  min-height: 150px;
  .subtitle {
    margin: 12px 0 24px 0;
    font-size: 14px;
    background: initial !important;
  }

  .sub-subtitle {
    font-size: 14px;
  }

  .paragraph {
    margin-top: 4px;
    font-size: 14px;

    &.paragraph-card__keywords {
      margin-bottom: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.paragraph-card__filters {
      margin-bottom: 8px;
      line-height: 20px;
      height: 60px;
      display: -webkit-box;
      word-break: break-word;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
</style>
