export const itemData = [
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 1.0</h3>',
    'caption': 'August 2020',
    'text': '',
    'features': [
      {
        'title': '',
        'description': 'The Digital Learning Resource Hub is a Digital Resource Exchange for teachers to discover, share and publish curriculum aligned resources. The first official release 1.0 of the Digital Learning Resources Hub, as a Minimal Viable Product, was in August 2020, providing MVP functionality with integration to NSW DoE Scootle and ABC Education resources. \n' +
          '<ul><li>UI: Web User interface; mobile responsive view; thumbnail view of resources</li>' +
          '<li>Search functionality</li>' +
          '<li>Resources: view resource information; download the resource; push through to MS Teams & Google Classroom</li>' +
          '<li>Integration: integration with NSW DoE Scootle resources; integration with ABC Education resources; metadata alignment for integrated resources; integration with Single Sign On (SSO), for authentication only </li>' +
          '<li>Analytics: capture of usage data through web analytics</li>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 1.1</h3>',
    'caption': 'September 2020',
    'text': '',
    'features': [
      {
        'title': '',
        'description': "Search functionality was limited by only matching exact words not partial or 'expected' text, e.g. math doesn't return maths, mathematics.  Enhancement to search was done to meet usual search expectations.\n" +
          '<ul>' +
          '<li>Searching: partial textual input to return words that match or contain the text input; searching upon NSW Syllabus codes</li>' +
          '<li>User Feedback Enhancements </li>' +
          '<ul><li>Searching: managing special characters in search terms; resources with exact match to multi-term search to appear first in search results</li></ul>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 1.2</h3>',
    'caption': 'November 2020',
    'text': '',
    'features': [
      {
        'title': '',
        'description': '<ul><li>General: replaced “KLA” with “Learning Area”</li>' +
          '<li>Searching: Advanced filter visible by default and can be hidden; clearing search text; search for Syllabus/Curriculum from within resource card</li>' +
          '<li>Sharing: provided button to “Get Link” and copy it</li>' +
          '<li>Integration: integration with stem.T4L library resources; integration with Distance Education – School resources</li>' +
          '<li>Resource card view: displayed number of views; improved visibility of “Flag/Report” icon; empty metadata fields display “Not Available”</li>' +
          '<li>User Feedback Enhancements</li>' +
          '<ul><li>Searching: retaining search filter when user navigates away then back to the search results page using “back” arrow; managing special characters in search terms, replacing special characters with a space i.e. Country/Place becomes Country Place; ensuring that resources are no longer displayed when the search field is cleared</li>' +
          '<li>Resource card view: removing duplicate resource cards; incrementing views within a resource card every time resource is clicked</li></ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 2.0</h3>',
    'caption': 'March 2021',
    'text': '',
    'features': [
      {
        'title': '',
        'description': 'This major release was rolled out to all schools, and included a comprehensive set of Reading & Numerary resources.\n' +
          '<ul>' +
          '<li>Searching: Added new entry in “Resource Type” of “Classroom Resource”</li>' +
          '<li>Resources: ensured specific Reading and Numeracy resources were made available in the URH and amended FAQ regarding inclusion of Reading and Numeracy resources</li>' +
          '<li>Resource card: provided links to “related resources” in the resource card</li>' +
          '<li>Access: provided all staff access</li>' +
          '<li>Reporting and analytics: provided data to allow for reporting and analytics</li>' +
          '<li>User Experience Enhancements:</li>' +
          '<ul>' +
          '<li>Accessibility: resolution of high priority accessibility issues</li>' +
          '</ul>' +
          '<li>User Feedback Enhancements</li>' +
          '<ul>' +
          '<li>Searching: ensuring that clearing a filter and then choosing the same filter again returns the same results; retaining selected Advanced filter after user navigates away and then back to the “browse resources” page</li>' +
          '<li>Community: ensuring ease of access to the DoE and URH Yammer pages</li>' +
          '</ul>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 2.1</h3>',
    'caption': 'March 2021 ',
    'text': '',
    'features': [
      {
        'title': '',
        'description': 'The primary objective of this release was to remediate accessibility compliance issues. Based on the remediation and subsequent assessment – URH achieved 89.4% accessibility compliance.' +
          '<ul>' +
          '<li>Reporting and analytics: Extended reporting and analytics functionality to capture events and retrieve logs for reporting; updated metadata of Reading and Numeracy resources.</li>' +
          '<li>Resources: inclusion of additional stem.T4L and Reading and Numeracy resources in URH; defined allowable resource title length for incoming Reading and Numeracy resources</li>' +
          '<li>Removed ABC Education, Distant Education and NSW DoE Scootle resources, pending a ‘quality’ review</li>' +
          '<li>User Experience Enhancements</li>' +
          '<ul><li>Accessibility: resolution of medium and low priority accessibility issues</li></ul>' +
          '<li>User Feedback Enhancements</li>' +
          '<ul><li>Sharing: ensuring timeliness of access to Google Classrooms; ensuring the flow of sharing is initiated by clicking the Share button; ensuring correct URL is shared for resources; ensuring stem.T4L resources can be shared to Microsoft Teams</li></ul>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 2.2</h3>',
    'caption': 'April 2021',
    'text': '',
    'features': [
      {
        'title': '',
        'description': '<ul>' +
          '<li>Resources: inclusion of additional stem.T4L and Reading and Numeracy resources in URH; updated metadata of Reading and Numeracy resources.</li>' +
          '<li>User Feedback Enhancements</li>' +
          '<ul><li>Resource view: ensuring that resource title displayed in “Related resources” matches the title of the resource </li></ul>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 2.3</h3>',
    'caption': 'June 2021',
    'text': '',
    'features': [
      {
        'title': '',
        'description': 'New layout for resource cards, including: accordion design to expand and collapse each distinct section; extra resources displayed down the right-hand side, including “More from your search” and “Most viewed”; syllabus outcomes displayed and searchable; progressions are displayed and searchable; professional learning links to related PL resources; static link to Universal Design for Learning Tool on all resources; National Literacy and Numeracy Learning Progressions section.\n' +
          '<ul>' +
          '<li>Searching: added Reading and Numeracy as a “Source” filter to support searching Reading and Numeracy Topics</li>' +
          '<li>User Experience Enhancements:' +
          '<ul><li>Mobile devices: Improved basic usability for Android and iOS devices, including the sharing functions.</li></ul></li>' +
          '<li>User Feedback Enhancements:' +
          '<ul>' +
          '<li>Resource card view:' +
          '<ul>' +
          '<li>ensuring that thumbnail format is retained irrespective of number of lines of text; </li>' +
          '<li>ensuring resource card is consistently visible upon zoom in or zoom out of browser; </li>' +
          '<li>ensuring that resource can be opened in a new tab using right click; </li>' +
          '<li>ensuring extra characters do not appear in resource description; </li>' +
          '<li>provision of vertical scroll bar when zoom is 50% or below; </li>' +
          '<li>ensuring consistency in user permissions to view and access resources i.e. Professional Learning resources; </li>' +
          '<li>ensuring consistency in access to Curriculum Alignment information in resource card</li>' +
          '</ul></li>' +
          '<li>Searching:' +
          '<ul>' +
          '<li>ensuring both multi-term and phrase search can be performed when Topics or Syllabus chips are clicked; </li>' +
          '<li>ensuring consistency in search results when multi-term or phrase search has been performed using Topics or Syllabus chips; </li>' +
          '<li>ensuring duplicate resources do not appear in search results; </li>' +
          '<li>provision of a vertical scroll bar for search results; </li>' +
          '<li>ensuring Sort By remains visible when Search field is cleared; </li>' +
          '<li>ensuring Sort By defaults to “Relevance”; </li>' +
          '<li>ensuring search mode is available when a filter is checked, irrespective of the search field being cleared; </li>' +
          '<li>ensuring duplicates do not appear in “Recommended for you” section, irrespective of how many search results have been returned; </li>' +
          '<li>ensuring that special characters, such as parentheses, are managed to allow searching </li>' +
          '</ul></li>' +
          '<li>Browser compatibility: ensuring share button is available in Safari to share to Microsoft Teams; ensuring that user is able to stay in the resource card view when sharing resource URL to Microsoft Teams; ensuring formatting is consistent across different browser settings</li>' +
          '<li>Sharing: ensuring that resources with spaces in their title (i.e. from Sharepoint) can be shared via link; ensuring that resources shared via direct URL can be accessed. </li>' +
          '</li></ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 2.4</h3>',
    'caption': 'September 2021',
    'text': '',
    'features': [
      {
        'title': '',
        'description': 'Additional Reading & Numeracy resources provided in the URH, along with metadata updates to existing resources.'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 3.0</h3>',
    'caption': 'December 2021',
    'text': '',
    'features': [
      {
        'title': '',
        'description': 'Release 3.0 includes a range of updates to existing functionality and content within URH, as well as introducing a new major feature called “My Resources” - relating to creation and collaboration of resources by a user.' +
          '<ul>' +
          '<li>Navigation link to the Digital Learning Selector (DLS)</li>' +
          '<li>Updated Home Page design to include libraries such as “Recently added for you”, “Most viewed for you”</li>' +
          '<li>Preferences: confirm Learning Area/s and Stage/s most associated with user’s area of interest, either at first log-in or at another time where user chooses “Remind me later”</li>' +
          '<li>Resource cards: ' +
          '<ul>' +
          '<li>grouping of displayed resource cards are defaulted using the user’s chosen preferences. For each of these groupings, the user can “See all”; </li>' +
          '<li>icons for Google Classrooms and MS Teams have been moved from Share via to the main view; </li>' +
          '<li>ability to “like” a resource and number of likes also appears on the main view; </li>' +
          '<li>icon and count of how many times the resource has been viewed; </li>' +
          '<li>user can hover over items in resource card and search on them; </li>' +
          '<li>when sharing via email, entering a name will generate DoE email addresses.</li>' +
          '</ul>' +
          '</li>' +
          '<li>Searching URH: See and filter by Stages in addition to Years; filters can be cleared as part of running a search; users can save up to 50 searches.</li>' +
          '<li>Searching MyResources: Filters have been updated to reflect the My Resources functionality. These filters can be cleared as part of running a search.</li>' +
          '<li>My Resources: This is an area unique to each user and can be accessed via the left-hand menu.</li>' +
          '<li>Creating resource card: A user can create a resource card within My Resources, including metadata and reference data such as curriculum alignment and copyright.' +
          '<ul>' +
          '<li>create using a range of file formats – Word, Excel, PDF, PowerPoint, png, jpeg or using a resource URL; </li>' +
          '<li>changes to a resource card can be made at any time; </li>' +
          '<li>changes to resource card generate a new version; </li>' +
          '<li>resource cards can be deleted at any time; </li>' +
          '<li>resource cards can be copied </li>' +
          '</ul><i><strong>Note</strong>: These resource cards <u>will not</u> be made available to the greater URH as part of this release.</i>' +
          '</li>' +
          '<li>Sharing resource card: share resource card (created, shared or found via search) as view-only with colleagues; share created resource card with collaborators as editable</li>' +
          '<li>Sharing resources: as per the existing URH sharing functionality, to share the resources created within My Resources with students, includes added consideration around copyright.</li>' +
          '<li>Notifications and information: upon logging in to URH/MyResources users can view notifications about actions taken against resource cards (created or shared); users can view information about a resource card</li>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 4.0</h3>',
    'caption': 'March 2022',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          'The <i>Digital Learning Resources Hub</i> has been rebranded to the <i>Universal Resources Hub</i>, as it will now include school improvement resources alongside the existing (and new) teaching and learning resources. <br><br> Other changes include:' +
          '<ul>' +
          '<li>School improvement resources have been added to the Universal Resources Hub and focus on the more holistic school planning activities that schools undertake. The sources for these resources include:' +
          '<ul>' +
          '<li>Attendance </li>' +
          '<li>Financial Management </li>' +
          '<li>Aboriginal HSC Attainment </li>' +
          '</ul>' +
          '</li>' +
          '<li>Finding the Universal Resources Hub:' +
          '<ul>' +
          '<li>User can search and find the <i>Universal Resources Hub</i> via search engines which will direct the user to an information page on the Education NSW website </li>' +
          '<li>Only staff can access the Universal Resources Hub and users will need to log in to access any resources </li>' +
          '</ul>' +
          '</li>' +
          '<li>Areas of interest: users will be offered to change their ‘onboarding’ settings and can choose areas of interest relating to \'school improvement\' resources, or \'teaching and learning\' resources by stage and learning area.</li>' +
          '<li>Search functionality: users can search on either category of ‘teaching and learning’ or ‘school improvement’ resources (or both), through accessing dynamic search filters specific to the category of resource. These filters include:' +
          '<ul>' +
          '<li>Schools Excellence Framework (SEF) Alignment – Domain and Elements </li>' +
          '<li>Focus Area </li>' +
          '<li>Phases of the teaching & learning or school improvement cycle </li>' +
          '</ul>' +
          '</li>' +
          '<li>Updates to search filters to align with the resource "categories" may result in some previously saved searches being affected by changes to the "type" filter.' +
          '</li>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 4.0.1</h3>',
    'caption': 'April 2022',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          '<ul>' +
          '<li>The source field has been renamed to "Owner" to more clearly represent who was responsible for providing the resource card in the Hub.' + '</li>' +
          '<li>A new Owner of "Inclusive practice" has been added to the Hub. This includes evidence-based practice resources for schools to support students with disability and additional needs. 58 resources have been created, with more to come.' + '</li>' +
          '<li>The name of "Reading & Numeracy" as an "Owner" (previously "Source") has been changed to "Literacy & Numeracy".' + '</li>' +
          '<li>Extra resources have been added for: School Excellence in Action: 10 new resources, Financial Management: 3 new resources.' + '</li>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 4.0.2</h3>',
    'caption': 'May 2022',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          '<ul>'
          +
          '<li>A new Owner of "Curriculum" has been added to the Hub. This includes quality assured learning resources produced by the specialist Curriculum, Secondary Learners and Curriculum, Early Years and Primary Learners teams. 9 resources have been created, with more to come.' + '</li>' +
          '<li>Where relevant, new learning resources will align to the most recent Maths & English K-2 syllabus and v3.0 of the National Literacy & Numeracy Progressions. ' + '</li>' +
          '<li>Extra resources have been added for: School Excellence in Action: 16 new resources, CESE / LEED (part of SEiA): 10 resources, Inclusive Practice: 62 resources, Curriculum: 9 resources.' + '</li>'
          +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 4.0.3</h3>',
    'caption': 'July 2022',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          '<ul>'
          +
          '<li>Introduction of an audited, back-end resource update tool for administrators to more easily add and manage content.' + '</li>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 4.0.4</h3>',
    'caption': 'July 2022',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          '<ul>'
          +
          '<li>New content owner of "EAL/D Education", with two cards.' + '</li>' +
          '<li>Addition of new resources from the following content owners:' + '</li>' +
          '<ul>' +
          '<li>Financial Management: 2</li>' +
          '<li>Inclusive Practice: 23</li>' +
          '<li>SEiA: 5</li>' +
          '<li>Literacy & Numeracy: 2</li>' +
          '<li>STEM.T4L Learning Library: 7</li>' +
          '</ul>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 4.2</h3>',
    'caption': 'October 2022',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          'New collections functionality allowing users to group resources and share with students and colleagues. ' +
          'You can also set learning sequences to display the suggested order in which resources could be delivered.'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 4.2.5</h3>',
    'caption': 'December 2022',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          'Minor enhancements including:'
          +
          '<li>Collections can now be sorted by latest date.' + '</li>' +
          '<li>Version 3 of the National Literacy and Numeracy progressions have been added, so Resource owners (such as the Literacy & Numeracy team) and user created \'My Resources\' can begin to align resource cards to the new progressions.' + '</li>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 4.5</h3>',
    'caption': 'October 2023',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          '<ul><li>Content creators are now able to produce ‘Collections’ of resources.' + '</li>' +
          '<li>‘Collections’ will pass through the URH workflow and relevant QA process to maintain relevance and quality.</li>' +
          '<li>These ‘Collections’ will be visible to users in the URH when searching' +
            '<ul><li>Using the ‘Library’ filter, users can segregate searching on ‘Resource cards’, ‘Collections’, or both.</li></ul>' +
          '</li>' +
          '<li>URH users will be able to ‘Follow’ any ‘Collections’ generated by the content areas, which will appear in a new feed in their ‘My Collections’ page called ‘Followed Collections’.' +
            '<ul><li>If a ‘Followed Collection’ is amended by the owner, e.g. resource cards added or removed, then the updates will display in the Collection in the ‘Overview’ section via the ‘Options’ button.</li></ul>' +
          '</li>' +
          '<li>Saving a ‘Collection’ will duplicate the original collection and make a new version of it for the user for them to augment as desired. Any updates will not appear in this copy.</li>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 5.0</h3>',
    'caption': 'December 2023',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          '<ul><li>Various workflow improvements and fixes within the content management Hub Resource Editor (HRE).</li>' +
          '<li>New administrator tool to manage HRE users.</li>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 5.1</h3>',
    'caption': 'January 2024',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          '<ul><li>Enabled the ability to auto-clear out draft resources from the HRE.</li></ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 5.2</h3>',
    'caption': 'March 2024',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          '<ul><li>Renamed ‘Yammer’ to ‘Viva Engage’ in the Community Tab.</li>' +
          '<li>On the Collection’s thumbnails in search results, allowed for full description to be displayed when user hovers over the first line of description.</li>' +
          '<li>Resolved a defect whereby ‘copy URL’ wasn’t functioning as expected. </li>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 5.3</h3>',
    'caption': 'May 2024',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          '<ul><li>Qualtrics - to enable user surveys. </li>' +
          '<li>On search result page, now limiting the number of focus areas visible on the resource card results to display the first 2, as they were not restricted, and more than 2 ‘spilled’ across the card.</li>' +
          '<li>On search result page, added ‘hover over text’ on the buttons so it’s clear what function the buttons provide.</li>' +
          '<li>Display short description on URH collections in card view. </li>' +
          '<li>Bug fixes </li>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 5.4</h3>',
    'caption': 'July 2024',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          '<ul><li>In ‘Collections’, it has now been made clearer that a ‘Learning sequence’ has been set by the author, with a banner at the top of the collection and the order in which the sequence has been defined against each resource card. </li>' +
          '<li>Updated the ‘Roadmap’ as it was missing the previous four release’s notes.</li>' +
          '<li>When adding resources to a collection in ‘My Collections’ there was no validation on whether the resource had already been added. This has been amended.</li>' +
          '<li>Fixed an issue in collections whereby the label didn’t display correctly when a user is ‘Following’ the collection.</li>' +
          '<li>Fixed an issue when a resource card in a search result has a lot of ‘Focus Areas’ they were pushing past the bottom of the thumbnail.</li>' +
          '<li>Resolved an issue in the ‘Community’ Tab - when navigating between ‘Viva Engage’ and ‘State-wide Staffrooms’, it wasn’t allowing users back to Viva Engage again.</li>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 5.5</h3>',
    'caption': 'August 2024',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          '<ul><li>Significant improvements to the URH Search function:' +
            '<ul><li>Provide ‘fuzzy’ results, so close matches or misspelt words are ‘corrected’ and returned.</li>' +
            '<li>Improvements to the accuracy of the results based on search terms</li>' +
            '<li> Result sorting enhancements. </li></ul></li>' +
          '<li>Collections:' +
            '<ul><li>Users can now visually see when ‘learning sequences’ have been set on collections, by showing a small number in the bottom right of the resource images and a banner at the top of the collection.</li>' +
            '<li>Fixed an issue when moving between resources on the right-hand side would not open the resource cards as expected.</li>' +
            '<li>Images were distorted when used in a collection. This has been fixed by left aligning the collection’s main image.</li>' +
            '<li>Fixed an issue where the ‘Options -> Overview’ list in collections wasn’t displaying all the changes made by the content owners.</li></ul></li>' +
          '<li>Introduction of new ‘content owner’ called ‘Literacy & Numeracy Resource Packages’. </li>' +
          '<li>Amended Literacy & Numeracy’s “R&N Topics” sub-filter:' +
            '<ul><li>Sub-filter is now called ‘L&N Topics’ </li>' +
            '<li>This filter ‘appears’ and is selectable when content owner Literacy & Numeracy and / or L&N Resource Packages is selected.</li>' +
            '<li>Updates to the entries within the L&N Topics list, resource cards aligned to the new entries.</li>' +
            '<li>Added the L&N Topics to the resource card details page. </li></ul></li>' +
          '<li>Fixed a bug where the home page ordering for stem.T4L and Reading & Numeracy content wasn’t sorted correctly.</li>' +
          '<li>Removed the links within resource card ‘Syllabus & Curriculum outcomes’ section. NESA have moved all syllabus content and these are now not available. Replaced the links with one link to the main curriculum page instead.</li>' +
          '<li>Fixes and minor improvements to the Resource Card Editor for content owners.</li>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 5.6</h3>',
    'caption': 'September 2024',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          '<ul><li>General:' +
          '<ul><li>Created a notification banner at the top of the URH, to advise end users on news, outages etc.</li>' +
          '<li>Added VET as Learning area.</li>' +
          '<li>Renamed Content owner ‘School Excellence in action’ to ‘School Excellence’</li>' +
          '<li> Amended the link for ‘HPGE’ within Community -> Statewide staffrooms to point to the MS Team channel instead of the sign-up link.</li>' +
          '<li> Added data to enable reporting on ‘Collections’ usage. </li>' +
          '<li> Background performance improvements. </li></ul></li>' +
          '<li>Collections:' +
          '<ul><li> Optimised the layout for ‘grid’ view of collections, reduce image sizes, added content.</li>' +
          '<li>Improved the image layout of collections in search results in thumbnail view.</li>' +
          '<li>Provided a ‘COLLECTION’ watermark in the top left corner when Collections are listed in Hub Resource page.</li>' +
          '<li>For content authors: Created validation that asks the user to verify or unpublish a collection when there is less than 2 resources in the collection.</li>' +
          '<li>Fixed a bug that the ‘number’ of resource cards in a collection didn’t update when others were added to the collection.</li>' +
          '<li>Fixed a bug whereby the notifications inside the ‘notification bell’ were not updating for all the changes made to collections.</li>' +
          '<li>Fixed a bug where the ‘number of views’ were not updating on resources when opening from within a collection.</li></ul></li>' +
          '<li>Resource Card Editor (RCE) (for content owners):' +
          '<ul><li>Disabled the ID field until a content owner selects the ‘Application’. This is to stop accidental duplication of resource card ID’s.</li>' +
          '<li>Fixed the cursor design when hovering over a resource card.</li>' +
          '<li>Minor changes to wording on some field headings/placeholders. </li></ul></li>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 5.7</h3>',
    'caption': 'October 2024',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          '<ul>' +
          '<li>Enhancement to searching to improve results on multi-term search criteria.</li>' +
          '<li>Notification Banner enhancements:' +
          '<ul><li> Create a carousel to enable multiple notifications at once.</li>' +
          '<li>Navigation and Dismiss buttons to allow for users to be navigated directly to related content or hide each notification.</li>' +
          '<li>Allow banners to show to users that have preferences set for School Improvement or Teaching & Learning resources (or both).</li></ul></li>' +
          '<li>Saved Searches:' +
          '<ul><li>2 bugs related to being able to re-run or display results for saved searches.</li></ul></li>' +
          '<li>Enabled new Feedback capability (bottom left corner).</li>' +
          '<li>Fixed an issue where My Collections were not showing all the resources within it (now a max 300).</li>' +
          '</ul>'
      }
    ],
    'value': false
  },
  {
    'type': 'release',
    'complete': true,
    'title': '<h3>Release 6.0</h3>',
    'caption': 'December 2024',
    'text': '',
    'features': [
      {
        'title': '',
        'description':
          '<ul>' +
          '<li>AI search:</li>' +
          '<ul><li> Implemented <strong>AI search</strong>, which interprets the meaning and context of words and phrases rather than standard search which only matches exact words.' +
          ' It allows for conversational, longer phrases found inside the resources, instead of returning results that match against keywords in the filters and description.</li>' +
          '<li> Changes to saved searches and copying/pasting/bookmarked URLs to check for the user’s selected search experience.</li>' +
          '</li></ul></li>' +
          '<li>School Excellence Framework (SEF) fields and search filters have been updated to align with the School Excellence Framework version 3 (SEF v3), instead of version 2.  Users can now search down to the ‘theme’ level.</li>' +
          '</ul>'
      }
    ],
    'value': false
  },
]
