<template>
  <div class="resources-heading__wrapper">
    <div class="resources-heading__left-content">
      <template>
        <div class="results__container">
          <template v-if="$store.state.isLoading">
            <v-skeleton-loader
              class="results__skeleton"
              type="text"
              :min-width="78"
            />
          </template>
          <template v-else-if="count === 0">
            No results.
          </template>
          <template v-else>
            <div class="d-flex justify-start">
              <div class="mt-2">
                <strong>{{ count }} </strong>
                <span v-if="$store.state.searchMode">
                  {{ count !== 1 ? 'results' : 'result' }}
                </span>
                <span v-else>{{ count !== 1 ? 'resources' : 'resource' }}</span>
              </div>
              <AdsButton
                v-if="count > 0 && !$store.state.isLoading"
                tertiary
                small
                :button-text="$vuetify.breakpoint.smAndUp ? 'Save this search': 'Save'"
                icon="mdi-content-save-outline"
                @click="saveSearchDialog = true"
              />
            </div>
          </template>
        </div>
      </template>
    </div>
    <div
      v-if="count > 0"
      class="resources-heading__right-content"
    >
      <template>
        <div class="sorting">
          <span
            v-if="$vuetify.breakpoint.mdAndUp"
            id="sorting-label"
          >
            Sort by:
          </span>
          <v-select
            v-model="sortSelect"
            aria-labelledby="sorting-label"
            class="sorting-menu"
            hide-details
            :items="sortOptions"
            dense
          />
        </div>
        <GridOrListToggle v-model="toggleCardView" />
      </template>
    </div>
    <Dialog
      v-model="saveSearchDialog"
      :title="saveUpdateSearchDialogTitle"
      :width="$vuetify.breakpoint.xsOnly ? 360 : 600"
    >
      <template #content>
        <SaveUpdateSearch
          v-model="saveSearchDialog"
          @title="getTitle"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import {AdsButton} from '@nswdoe/doe-ui-core';
import GridOrListToggle from '@/components/ResultsHeader/GridOrListToggle';
import Dialog from '@/components/Dialogs/Dialog';
import SaveUpdateSearch from '@/views/SavedSearchesPage/SaveUpdateSearch';
import {SORT_OPTIONS, MY_RESOURCES_SORT_OPTIONS} from '@/constants';

export default {
  name: 'SearchResultsHeader',
  components: {
    AdsButton,
    GridOrListToggle,
    Dialog,
    SaveUpdateSearch
  },
  props: {
    count: {
      type: [Number, String],
      default: ''
    },
    sorting: {
      type: [Object, String],
      default: {
        field: 'relevance'
      }
    },
    showView: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      sortOptions: null,
      saveSearchDialog: false,
      saveUpdateSearchDialogTitle: 'Save this search'
    }
  },
  computed: {
    toggleCardView: {
      get() {
        return this.showView || 0
      },
      set(value) {
        this.$emit('toggle:view', value);
      }
    },
    sortSelect: {
      get() {
        let sort;
        if (this.sorting.direction) {
          sort = this.sorting.field + ':' + this.sorting.direction;
        } else {
          sort = this.sorting.field;
        }
        switch (sort) {
          case 'relevance':
            return {
              text: 'Relevance',
              value: 'relevance'
            };
          case 'created:desc':
            return {
              text: 'Created Date',
              value: 'created:desc'
            };
          case 'updated:desc':
            return {
              text: 'Updated Date',
              value: 'updated:desc'
            };
          case 'name:asc':
            return {
              text: 'Name',
              value: 'name:asc'
            };
          case 'numberViews:desc':
            return {
              text: 'Most viewed',
              value: 'numberViews:desc'
            };
          case 'numberLikes:desc':
            return {
              text: 'Likes',
              value: 'numberLikes:desc'
            };
        }
        return {
          text: 'Relevance',
          value: 'relevance'
        };
      },
      set(value) { // value = relevance, name:asc, etc
        this.$store.commit('CLEAR_SEARCH');
        const sortSearchObj = {};
        if (value.includes(':')) {
          const arr = value.split(':');
          sortSearchObj.field = arr[0];
          sortSearchObj.direction = arr[1];
        } else {
          sortSearchObj.field = value;
        }
        this.$store.commit('SET_SORT', sortSearchObj);
        this.$store.dispatch('fetchResourcesWithClient');
      }
    },
  },
  created() {
    this.sortOptions = SORT_OPTIONS;
  },
  methods: {
    getTitle(title) {
      this.saveUpdateSearchDialogTitle = title;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.resources-heading__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 12px 0;
  min-height: 76px;

  .resources-heading__left-content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .results__container {
      min-width: 90px;

      .results__skeleton {
        &.v-skeleton-loader {
          ::v-deep.v-skeleton-loader__heading {
            width: 100%;
          }
        }
      }

      p {
        margin-bottom: 0;
        margin-right: 8px;

        .redirect-search-link {
          color: $ads-blue-1;
        }
      }
    }

    .v-btn {
      font-size: 16px;

      &:hover {
        &:before {
          opacity: 0 !important;
        }
      }
    }
  }

  .resources-heading__right-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .sorting {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      #sorting-label {
        padding-right: 12px;
      }

      .sorting-menu {
        max-width: 180px;
        padding-right: 24px;
      }
    }
  }
}

::v-deep.v-list {
  .v-list-item {
    transition: background-color 0.3s ease-out;
  }

  .v-list-item.v-list-item--highlighted {
    border-collapse: separate; /* for ie 9-11 */
    box-shadow: 0 0 0 3 $ads-blue-3-dark inset;

    &:before {
      opacity: 0;
    }
  }

  &.theme--light {
    .v-list-item.v-list-item--active {
      background-color: $ads-navy;
      color: $ads-white !important;
    }
  }

  &.theme--dark {
    .v-list-item.v-list-item--active {
      background-color: $ads-navy-dark;
      color: $ads-01dp !important;

      &:before {
        opacity: 0;
      }
    }
  }
}

@media only screen and (max-width: 620px) {
  .resources-heading__wrapper {
    align-items: center;
    min-height: 40px;

    .resources-heading__left-content {
      padding-left: 12px;

      .results__container {
        min-width: 80px;

        p {
          max-width: 200px;
        }
      }
    }

    .resources-heading__right-content {
      padding-right: 12px;
      align-items: flex-end;

      .sorting {
        .sorting-menu {
          padding-right: 0;
          max-width: 140px;
        }
      }
    }
  }
}
</style>
