<template>
  <div class="content">
    <PreviewNewImage />

    <PreviewResourceInfo
      :isNewResource="isNewResource"
    />

    <v-row class="expansion-panel">
      <v-col
        cols="12"
        class="expansion-panel__wrapper"
      >
        <AdsExpansionPanel
          v-model="panelPreview"
          :items="expansionPanelItemsPreview"
          multiple
        >
          <template #contentOverview>
            <PreviewCardOverview />
          </template>
          <template #contentCurriculumAlignment>
            <PreviewCardCurriculumAlignment />
          </template>
          <template #contentProgressions>
            <PreviewProgressions />
          </template>
          <template #contentSEFAlignment>
            <SEFAlignment class="px-6" :sef-alignment="($store.state.editor.resourceDetails?.record?.status === ResourceStatus.published ? $store.state.editor.resourceDetails?.document : $store.state.editor.resourceDetails?.draft).SEFAlignment" />
          </template>
          <template #contentKeywords>
            <PreviewKeywords />
          </template>
        </AdsExpansionPanel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {AdsExpansionPanel} from '@nswdoe/doe-ui-core';
import PreviewNewImage from './PreviewNewImage';
import PreviewResourceInfo from '@/views/ResourceEditorDetailsPage/Preview/PreviewResourceInfo';
import PreviewCardOverview from '@/views/ResourceEditorDetailsPage/Preview/PreviewCardOverview';
import PreviewCardCurriculumAlignment from '@/views/ResourceEditorDetailsPage/Preview/PreviewCardCurriculumAlignment';
import PreviewProgressions from '@/views/ResourceEditorDetailsPage/Preview/PreviewProgressions';
import PreviewKeywords from '@/views/ResourceEditorDetailsPage/Preview/PreviewKeywords';
import {EXPANSION_PANEL_ITEMS_EDITOR_PREVIEW, ResourceStatus} from '@/constants';
import SEFAlignment from '@/components/SEFAlignment/SEFAlignment';

export default {
  name: 'Preview',
  components: {
    SEFAlignment,
    PreviewResourceInfo,
    PreviewNewImage,
    AdsExpansionPanel,
    PreviewCardOverview,
    PreviewCardCurriculumAlignment,
    PreviewProgressions,
    PreviewKeywords
  },
  props: {
    isNewResource: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      expansionPanelItemsPreview: EXPANSION_PANEL_ITEMS_EDITOR_PREVIEW,
      panelPreview: [0],
      ResourceStatus
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.row {
  margin-top: 0;
  margin-bottom: 36px;

  .col-12 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.content {
  width: 80%;
}

::v-deep .theme--light {
  .v-btn {
    &:hover {
      &::before {
        opacity: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .row {
    margin-bottom: 24px;
  }

  .content {
    width: 100%;

    .autocomplete__wrapper {
      margin-top: 24px;
    }
  }
}

@media only screen and (max-width: 600px) {
}
</style>
