<template>
  <div >
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <AdsButton
          icon="mdi-plus-circle-outline"
          button-text="Add"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-container class="white pa-0" style="min-width:600px">
        <v-treeview
          v-model="selectedItems"
          open-on-click
          selected-color="#041E42"
          selection-type="leaf"
          return-object
          :items="items"
          :class="{'treeview-group-options': !selectableParent}"
          selectable
          hoverable
          activatable
        >
        </v-treeview>
      </v-container>
    </v-menu>
  </div>
</template>
<script>
import { ADS_Colors, AdsButton } from '@nswdoe/doe-ui-core';

export default {
  name: 'TreeViewSelect',
  components: {
    AdsButton
  },
  data() {
    return {
      ADS_Colors
    }
  },
  props: {
    label: {
      type: String,
      default: 'Select'
    },
    heading: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'checkbox'
    },
    multiLevel: {
      type: Boolean,
      default: false
    },
    selectableParent: {
      type: Boolean,
      default: true
    },
    hideArrows: {
      type: Boolean,
      default: false
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    clearLink: {
      type: Boolean,
      default: false
    },
    clearLinkText: {
      type: String,
      default: 'Clear filter'
    },
  },
  computed: {
    selectedItems: {
      get() {
        return this.$attrs.value ? this.$attrs.value : [];
      },
      set(value) {
        // if (this.type === TYPE.CHECKBOX) {
        //   document.activeElement.blur();
        // }
        this.$listeners.input && this.$listeners.input(value);
      }
    },
  },
  methods: {
    handleSelect(item, open, e) {
      console.log('item selected', item)
      if (this.isAllSelected(item)) {
        if (open) {
          e.stopPropagation();
        }
        this.unSelectItem(item);
      } else {
        if (open) {
          e.stopPropagation();
        }
        this.selectItem(item);
      }
    },
    selectItem(item) {
      console.log('selecting item', item.name)
      if (item.children) {
        item.children.forEach(c => this.selectItem(c));
      } else {
        if (!this.selectedItems.includes(item.id)) {
          this.selectedItems.push(item.id);
          this.selectedItems = [...this.selectedItems];
        }
      }
      console.log('selected items', this.selectedItems)
    },
    unSelectItem(item) {
      if (item.children) {
        item.children.forEach(c => this.unSelectItem(c));
      } else {
        this.selectedItems.forEach((el, i) => {
          if (el === item.id) {
            this.selectedItems.splice(i, 1);
          }
        });
      }
    },
    isAllSelected(item) {
      if (item.children) {
        return item.children.every(c => this.isAllSelected(c));
      } else {
        if (this.selectedItems) {
          return this.selectedItems.includes(item.id);
        }
      }
    },
    isAllUnselected(item) {
      if (item.children) {
        return item.children.every(c => this.isAllUnselected(c));
      } else {
        if (this.selectedItems) {
          return !this.selectedItems.includes(item.id);
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../scss/variables.scss";

::v-deep.v-autocomplete {
  padding: 0;
}

.theme--light {
  ::v-deep.v-messages {
    text-align: right !important;
  }
}

::v-deep.v-select {
  &.v-select--chips:not(.v-text-field--single-line) {
    &.v-text-field--enclosed {
      .v-select__selections {
        min-height: 56px;
      }
    }
  }
}

.theme--light {
  ::v-deep.v-icon {
    &:focus {
      border: 2px solid $ads-navy;
      border-radius: 50%;

      &::after {
        opacity: 0;
      }
    }
  }

  ::v-deep.v-chip {
    background: $ads-white;
    border: 1px solid $ads-navy;

    .v-chip__content {
      button {
        &:focus {
          border: none !important;
          outline: 2px auto $ads-navy;
        }
      }
    }
  }
}
</style>
