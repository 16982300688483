var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.itemDetail)?_c('v-row',{staticClass:"image__footer"},[_c('div',{staticClass:"first-col"},[_c('div',{staticClass:"left-content"},[_c('span',{staticClass:"like-button"},[(_vm.likeClickable)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClickLikeIcon.apply(null, arguments)}}},[(_vm.liked)?_c('v-icon',{attrs:{"aria-label":"number of views","role":"img","aria-hidden":"false"}},[_vm._v(" mdi-thumb-up ")]):_c('v-icon',{attrs:{"aria-label":"number of views","role":"img","aria-hidden":"false"}},[_vm._v(" mdi-thumb-up-outline ")])],1):_vm._e(),(!_vm.likeClickable)?_c('v-btn',{attrs:{"icon":"","small":"","disabled":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClickLikeIcon.apply(null, arguments)}}},[(_vm.liked)?_c('v-icon',{attrs:{"aria-label":"number of views","role":"img","aria-hidden":"false"}},[_vm._v(" mdi-thumb-up ")]):_c('v-icon',{attrs:{"aria-label":"number of views","role":"img","aria-hidden":"false"}},[_vm._v(" mdi-thumb-up-outline ")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.numberLikes)+" ")],1),_c('span',[_c('v-icon',{staticClass:"material-icons-outlined",attrs:{"aria-label":"number of views","role":"img","aria-hidden":"false"}},[_vm._v(" remove_red_eye ")]),_vm._v(" "+_vm._s(_vm.itemMiscDetail.Views)+" ")],1)]),_c('div',{staticClass:"right-content"},[_c('AdsButton',{staticClass:"pl-1 pr-2",attrs:{"icon":"bookmarks","button-text":"Add to collection","tertiary":""},on:{"click":function($event){_vm.showSaveToCollectionDialog = !_vm.showSaveToCollectionDialog}}}),_c('SaveToCollectionDialog',{attrs:{"items":[{..._vm.itemDetail}],"selected-cards":[{
          ..._vm.itemDetail,
          Application: _vm.resourceDetails?.audit?.iss
        }]},model:{value:(_vm.showSaveToCollectionDialog),callback:function ($$v) {_vm.showSaveToCollectionDialog=$$v},expression:"showSaveToCollectionDialog"}})],1)]),_c('div',{staticClass:"second-col"},[_c('div',{staticClass:"share-button__wrapper"},[_c('Share',{staticClass:"share-button",attrs:{"item":{
          id:_vm.itemDetail.ItemID,
          url: _vm.resourceUrl,
          name: _vm.itemDetail.Name,
          statement: _vm.itemDetail.Rights
        }}})],1),_c('div',{staticClass:"resource-button__wrapper"},[_c('GoToResources',{attrs:{"id":_vm.itemDetail.ItemID,"primary":"","icon":"arrow_drop_down","button-text":"Go to resources","align-right":"","items":_vm.resourceList,"item-url":_vm.resourceUrl,"name":_vm.itemDetail.Name,"source":_vm.resourceDetails.record.collection}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }