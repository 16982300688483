<template>
  <div
    v-if="itemDetail"
    class="details__wrapper"
  >
    <v-row
      v-if="itemDetail.ThumbnailImage"
      class="image__container"
    >
      <v-col cols="12">
        <v-img
          :src="errorLoadingImage ? resourceDefaultImage : itemDetail.ThumbnailImage"
          :aspect-ratio="1.85"
          :contain="false"
          @error="handlerError"
        />
      </v-col>
    </v-row>

    <ImageFooter />

    <ResourceInfo />

    <v-row class="expansion-panel">
      <v-col
        cols="12"
        class="expansion-panel__wrapper"
      >
        <AdsExpansionPanel
          v-model="panel"
          :items="expansionPanelItems"
          multiple
        >
          <template #contentOverview>
            <CardOverview
              :isLearningResource="isLearningResource"
            />
          </template>
          <template #contentCurriculumAlignment>
            <CardCurriculumAlignment />
          </template>
          <template #contentProgressions>
            <Progressions />
          </template>
          <template #contentSEFAlignment>
            <SEFAlignment class="px-6" :sef-alignment="itemDetail.SEFAlignment"/>
          </template>
          <template #contentKeywords>
            <Keywords />
          </template>
        </AdsExpansionPanel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ImageFooter from '@/views/HubResourceDetailsPage/ResourceDetails/ImageFooter';
import ResourceInfo from '@/views/HubResourceDetailsPage/ResourceDetails/ResourceInfo';
import CardOverview from '@/views/HubResourceDetailsPage/ResourceDetails/CardOverview';
import CardCurriculumAlignment from '@/views/HubResourceDetailsPage/ResourceDetails/CardCurriculumAlignment';
import Progressions from '@/views/HubResourceDetailsPage/ResourceDetails/Progressions';
import Keywords from '@/views/HubResourceDetailsPage/ResourceDetails/Keywords';
import {AdsExpansionPanel} from '@nswdoe/doe-ui-core';
import resourceDefaultImage from '@/assets/default_myresources_image.png';
import {mapState} from 'vuex';
import isEqual from 'lodash.isequal';
import SEFAlignment from '@/components/SEFAlignment/SEFAlignment';

export default {
  name: 'ResourceDetails',
  components: {
    SEFAlignment,
    ResourceInfo,
    ImageFooter,
    AdsExpansionPanel,
    CardOverview,
    CardCurriculumAlignment,
    Progressions,
    Keywords
  },
  data() {
    return {
      originalExpansionPanelItems: [
        {
          id: 'Overview',
          title: 'Overview',
        }
      ],
      expansionPanelItems: [],
      errorLoadingImage: false,
      panel: [0],
      resourceDefaultImage
    }
  },
  computed: {
    ...mapState({
      itemDetail: state => state.itemDetail
    }),
    isLearningResource() {
      return this.itemDetail?.ResourceCategory?.includes('tlr') || false;
    },
  },
  watch: {
    itemDetail: {
      immediate: true,
      deep: true,
      handler(itemDetails, prevDetails) {
        if (isEqual(itemDetails, prevDetails)) {
          return;
        }
        this.errorLoadingImage = false;
        this.generatePanels();
      }
    }
  },
  beforeUnmount() {
    this.errorLoadingImage = false;
  },
  methods: {
    handlerError(src) {
      this.errorLoadingImage = true;
      console.error('error image src = ', src);
    },
    isPanelAdded(id) {
      return this.expansionPanelItems.some(i => i.id === id);
    },
    generatePanels() {
      const hasNumProgression = this.itemDetail?.NumLearnProgLevel && this.itemDetail.NumLearnProgLevel.length > 0;
      const hasLitProgression = this.itemDetail?.LitLearnProgLevel && this.itemDetail.LitLearnProgLevel.length > 0;
      const hasNumProgressionV3 = this.itemDetail?.NumLearnProgLevelV3 && this.itemDetail.NumLearnProgLevelV3.length > 0;
      const hasLitProgressionV3 = this.itemDetail?.LitLearnProgLevelV3 && this.itemDetail.LitLearnProgLevelV3.length > 0;

      // reconstructing the panels when the item is changed
      this.expansionPanelItems = [...this.originalExpansionPanelItems];

      this.isLearningResource && this.expansionPanelItems.push({
        id: 'CurriculumAlignment',
        title: 'Syllabus /  Curriculum Alignment',
      });

      if (hasNumProgression || hasLitProgression || hasNumProgressionV3 || hasLitProgressionV3) {
        this.expansionPanelItems.push({
          id: 'Progressions',
          title: 'National Literacy and/or Numeracy Learning Progression(s)',
        });
      }

      if (this.itemDetail?.SEFAlignment?.length > 0) {
        this.expansionPanelItems.push({
          id: 'SEFAlignment',
          title: 'School Excellence Framework',
        })
      }

      if (this.itemDetail?.keywords?.length !== 0) {
        this.expansionPanelItems.push({
          id: 'Keywords',
          title: 'Keywords',
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';

.details__wrapper {
  .image__container {
    margin: 0;

    .col {
      padding: 0;

      .v-image {
        border-radius: 4px;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .details__wrapper {
    .image__container {
      .col {
        .v-image {
          border-radius: 0;
        }
      }
    }

    .expansion-panel {
      margin: 0;

      .expansion-panel__wrapper {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
}

@media only screen and (max-width: 670px) {
  .details__wrapper {
    .expansion-panel {
      .expansion-panel__wrapper {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
}
</style>
