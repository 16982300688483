<template>
  <div :class="{'pt-2 pb-4': $vuetify.breakpoint.xs, 'pt-2 pb-6': $vuetify.breakpoint.smAndUp } ">
    <v-row no-gutters v-if="Object.keys(learning).length > 0">
      <v-col cols="12">
        <h4 class="mb-1">
          Learning
        </h4>
        <div v-for="(elements, i) in learning" :key="i" class="pl-0">
          <v-row v-if="displayAsChips">
            <v-col cols="12" class="pt-5">
              <h5> {{ i }} </h5>
              <v-chip
                v-for="(element, itemIndex) in elements"
                :key="element.id + itemIndex"
                tabindex="0"
                color="#041E42"
                outlined
                text-color="#041E42"
                class="chip ma-2"
                role="button"
                @click="$emit('chipSelected', element.name)"
                @keyup.enter="$emit('chipSelected', element.name)"
              >
                {{ element.name }}
              </v-chip>
            </v-col>
          </v-row>
          <div v-else>
            <span>
              <strong> {{ i }} </strong> -
            </span>
            <span>
              {{ elements.map(i => i.name).join(', ') }}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="Object.keys(teaching).length > 0">
      <v-col cols="12">
        <h4 class="mb-1">
          Teaching
        </h4>
        <div v-for="(elements, i) in teaching" :key="i" class="pl-0">
          <v-row v-if="displayAsChips">
            <v-col class="pt-5">
              <h5> {{ i }} </h5>
              <v-chip
                v-for="(element, itemIndex) in elements"
                :key="element.id + itemIndex"
                tabindex="0"
                color="#041E42"
                outlined
                text-color="#041E42"
                class="chip ma-2"
                role="button"
                @click="$emit('chipSelected', element.name)"
                @keyup.enter="$emit('chipSelected', element.name)"
              >
                {{ element.name }}
              </v-chip>
            </v-col>
          </v-row>
          <div v-else>
            <span>
              <strong> {{ i }} </strong> -
            </span>
            <span>
              {{ elements.map(i => i.name).join(', ') }}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="Object.keys(leading).length > 0">
      <v-col cols="12">
        <h4 class="mb-1">
          Leading
        </h4>
        <div v-for="(elements, i) in leading" :key="i" class="pl-0">
          <v-row v-if="displayAsChips">
            <v-col cols="12" class="pt-5">
              <h5> {{ i }} </h5>
              <v-chip
                v-for="(element, itemIndex) in elements"
                :key="element.id + itemIndex"
                tabindex="0"
                color="#041E42"
                outlined
                text-color="#041E42"
                class="chip ma-2"
                role="button"
                @click="$emit('chipSelected', element.name)"
                @keyup.enter="$emit('chipSelected', element.name)"
              >
                {{ element.name }}
              </v-chip>
            </v-col>
          </v-row>
          <div v-else>
            <span>
              <strong> {{ i }} </strong> -
            </span>
            <span>
              {{ elements.map(i => i.name).join(', ') }}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import _ from 'lodash';

export default {
  name: 'SEFAlignment',
  props: {
    displayAsChips: {
      type: Boolean,
      default: false
    },
    sefAlignment: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    groupByKey(arr, key) {
      return arr.reduce((r, a) => {
        r[a[key]] = [...r[a[key]] || [], a];
        return r;
      }, {});
    }
  },
  computed: {
    ...mapGetters({
      sefAlignmentV3Elements: 'metadata/sefAlignmentV3Elements'
    }),
    ...mapState({
      itemDetail: state => state.itemDetail,
    }),
    sefV3() {
      return this.sefAlignment.map(item => {
        const str = item.id.split('.')
        return {
          ...item,
          domain: str[0],
          element: this.sefAlignmentV3Elements.find( item => item.elementId === [str[0], str[1]].join('.')).element
        }
      })
    },
    leading() {
      return this.groupByKey(this.sefV3.filter(item => item.id.startsWith('leading.')), 'element')
    },
    learning() {
      return this.groupByKey(this.sefV3.filter(item => item.id.startsWith('learning.')), 'element')
    },
    teaching() {
      return this.groupByKey(this.sefV3.filter(item => item.id.startsWith('teaching.')), 'element') || []
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-chip {
  .v-chip__content {
    max-width: 250px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
