<template>
  <div :class="{'px-0 pt-2 pb-4': $vuetify.breakpoint.xs, 'px-6 pt-6 pb-6': $vuetify.breakpoint.smAndUp } ">
    <FieldRowWithChips
      class="ml-n2"
      label="Topics"
      :items="topics"
    />
    <!-- Old syllabus (released 2012) -->
    <FieldRowWithChips
      class="ml-n2"
      label="NSW Syllabus"
      :items="syllabus"
    />
    <!-- New syllabus (released 2021) -->
    <!--    <FieldRowWithChips-->
    <!--      label="NSW Syllabus"-->
    <!--      text="(released 2021)"-->
    <!--      :items="syllabus"-->
    <!--    />-->
    <FieldRowWithChips
      v-if="substrand.length > 0"
      class="ml-n2"
      label="Substrand"
      :items="substrand"
    />
    <FieldRowWithChips
      class="ml-n2"
      label="Syllabus Outcome(s)"
      :items="outcomes"
    />
    <FieldRowWithChips
      class="ml-n2"
      label="Progression V2"
      :items="progressions"
    />
    <FieldRowWithChips
      class="ml-n2"
      label="Progression V3"
      :items="progressionsV3"
    />
    <FieldRowWithChips
      v-if="owner"
      class="ml-n2"
      label="Owner"
      :items="owner?[owner]:null"
    />

    <FieldRowWithChips
      v-if="lnnTopics"
      class="ml-n2"
      label="L&N Topics"
      :items="lnnTopics"
    />

    <FieldRowWithChips
      class="ml-n2"
      label="Focus area"
      :items="focusArea"
    />

    <FieldRowWithChips
      class="ml-n2"
      label="Phase"
      :items="phases"
    />

    <FieldRowWithChips
      class="ml-n2"
      label="School type"
      :items="schoolTypes"
    />

    <FieldRowWithChips
      class="ml-n2"
      label="SEF Alignment"
      :items="sefAlignment"
    />

    <v-row no-gutters class="mt-4">
      <v-col cols="3" class="px-1">
        <h4 class="mb-1">SEF Alignment:</h4>
      </v-col>
      <v-col cols="9" class="pl-2">
        <SEFAlignment :sef-alignment="sefAlignment" display-as-chips />
      </v-col>
    </v-row>

    <FieldRowWithChips
      class="ml-n2"
      label="SIP Terms"
      :items="sipTerms"
    />

    <FieldRowWithLinks
      class="ml-n2"
      label="Related Resources"
      :items="relatedResources"
    />

    <FieldRowWithLinks
      class="ml-n2"
      label="Professional Learning"
      :items="professionalLearning"
    />

    <FieldRow
      label="Publisher"
      :value="publisher"
    />

    <FieldRow
      label="Author"
      :value="author"
    />

    <FieldRow
      label="Resource"
      :value="resourceType"
    />

    <FieldRow
      label="Created Date"
      :value="dateCreated"
    />

    <FieldRow
      label="Updated Date"
      :value="dateModified"
    />

    <FieldRow
      label="Rights"
      :value="rights"
    />
  </div>
</template>

<script>
import moment from 'moment';
import {ADS_Colors} from '@nswdoe/doe-ui-core';
import {mapState} from 'vuex';
import FieldRow from '@/components/FieldRow'
import FieldRowWithChips from '@/components/FieldRowWithChips'
import FieldRowWithLinks from '@/components/FieldRowWithLinks'
import {ResourceStatus} from '@/constants';
import SEFAlignment from '@/components/SEFAlignment/SEFAlignment';

export default {
  name: 'PreviewCardOverview',
  components: {
    SEFAlignment,
    FieldRow,
    FieldRowWithChips,
    FieldRowWithLinks
  },
  data() {
    return {
      moment,
      ADS_Colors
    }
  },
  computed: {
    ...mapState({
      document: state => state.editor.resourceDetails?.record?.status === ResourceStatus.published ? state.editor.resourceDetails?.document : state.editor.resourceDetails?.draft,
      resourceDetails: state => state.editor.resourceDetails
    }),
    isEquella () {
      return this.resourceDetails?.audit?.iss === 'equ'
    },
    topics() {
      return this.document?.Topic?.length > 0 ? this.document.Topic : [];
    },
    owner() {
      return this.$store.getters['metadata/sources'].find(s => s.id === this.document?.Source)?.name || '';
    },
    lnnTopics() {
      if (['readingandnumeracy','literacyandnumeracypackages'].includes(this.document?.Source)) {
        return this.document?.RandNTopic?.map(topic => {
          return this.$store.getters['metadata/randntopics']?.find(type => {
            return type.id === topic
          })?.name || null;
        }).filter(Boolean)
      } else {
        return null
      }
    },
    phases() {
      return this.document?.Phase?.map(phase => phase.phase) || [];
    },
    syllabus() {
      return this.document?.NSWSyllabus?.map(syllabus => syllabus.name) || [];
    },
    substrand() {
      return this.document?.Substrand || [];
    },
    outcomes() {
      return this.document?.NSWSyllabus?.reduce(function (outcomes, syllabus) {
        return [...outcomes, ...syllabus.outcomes?.map(outcome => outcome.id)]
      }, []);
    },
    progressions() {
      const literacyLevels = this.document?.LitLearnProgLevel?.map(progression => progression.level) || [];
      const numeracyLevels = this.document?.NumLearnProgLevel?.map(progression => progression.level) || [];
      return literacyLevels.concat(numeracyLevels);
      // return this.document?.NumLearnProgLevel.concat(this.document?.LitLearnProgLevel);
    },
    progressionsV3() {
      const literacyLevels = this.document?.LitLearnProgLevelV3?.map(progression => progression.level) || [];
      const numeracyLevels = this.document?.NumLearnProgLevelV3?.map(progression => progression.level) || [];
      return literacyLevels.concat(numeracyLevels);
      // return this.document?.NumLearnProgLevel.concat(this.document?.LitLearnProgLevel);
    },
    publisher() {
      return this.document?.Publisher ? this.document.Publisher : 'None';
    },
    author() {
      // @TODO check with BE why this is String for School Improvement Resources
      return Array.isArray(this.document?.Author) && this.document?.Author.join(', ') || this.document?.Author;
    },
    resourceType() {
      // Lookup in metadata for the resourceType name
      const allHubResourceTypes = this.$store.getters['metadata/allHubResourceTypes']
      return allHubResourceTypes.length > 0 && allHubResourceTypes?.find(type => {
        return type.id === this.document?.ResourceType
      })?.name || 'Resource';
    },
    schoolTypes() {
      return this.document?.SchoolType?.length > 0 ? this.document?.SchoolType : []
    },
    focusArea() {
      return this.document?.FocusArea?.map(f => f.focusArea).filter(Boolean) || [];
    },
    sefAlignment() {
      return this.document?.SEFAlignment || [];
    },
    sipTerms() {
      return this.document?.SIPTerms?.length > 0 ? this.document.SIPTerms : []
    },
    relatedResources() {
      return this.document?.RelatedResources ? this.document.RelatedResources : []
    },
    professionalLearning() {
      return this.document?.PLLinks ? this.document.PLLinks : []
    },
    disclaimer() {
      return this.rights + (this.imageRights ? ' Image courtesy of ' + this.imageRights : '')
    },
    rights() {
      return this.document?.Rights ? this.document.Rights : this.isEquella ? '' : 'Copyright not yet defined. Note: Resource cannot be shared until provided.';
    },
    imageRights() {
      return this.document?.ResourceImage?.author ? this.document.ResourceImage.author : '';
    },
    dateCreated() {
      if (this.resourceDetails?.record?.created) {
        return this.moment(this.resourceDetails.record.created).format('DD MMM YYYY');
      } else {
        return this.moment(new Date()).format('DD MMM YYYY');
      }
    },
    dateModified() {
      if (this.resourceDetails?.record?.modified) {
        return this.moment(this.resourceDetails.record.modified).format('DD MMM YYYY');
      } else {
        return this.moment(new Date()).format('DD MMM YYYY');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';

.row {
  margin-left: 0;
  margin-right: 0;
}

.pllink {
  color: $ads-blue-1;
}

.chip:focus {
  color: $ads-white !important;
}

::v-deep.v-chip.v-chip--outlined.v-chip.v-chip:focus {
  background-color: $ads-navy !important;
}


</style>
