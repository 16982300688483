<template>
  <v-card
    :width="size"
    height="300"
    :class="{'rounded-0': $vuetify.breakpoint.width < 690}"
    :title="hoverText"
  >
    <v-row no-gutters class="header">
      <v-col cols="8">
        <v-chip
          small
          :color="isAISearch?ADS_Colors.Blue_3_Dark:ADS_Colors.Light_20"
        >
          {{ isAISearch? 'AI SEARCH': 'STANDARD SEARCH' }}
        </v-chip>
      </v-col>
      <v-col cols="4">
        <div class="header__actions d-flex justify-end">
          <v-btn
            icon
            small
            aria-label="delete"
            @click="deleteDialog = true"
            @keyup.enter="deleteDialog = true"
          >
            <v-icon
              color="#CE0037"
              class="material-icons-outlined"
            >
              delete
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            class="ml-1"
            aria-label="edit"
            @click="handleUpdate"
            @keyup.enter="handleUpdate"
          >
            <v-icon class="material-icons-outlined">
              edit
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <div
      role="button"
      tabindex="0"
      class="primary-control"
      @click="checkSearch"
      @keyup.enter="checkSearch"
    >
      <div class="d-flex align-start flex-column">
        <v-card-title class="d-flex pl-0">
          <span v-if="resourceCategory">
            <FeatureIcon
              v-for="cat in resourceCategoryDetails"
              :key="cat.id"
              size="20"
              :label="cat.label"
              :color="cat.iconColor"
              :backgroundColor="cat.iconBg"
              :icon="cat.icon"
              class="mr-2"
            />
          </span>
          <h2 :class="{'card-title__mdAndDown': size < 320, 'card-title': true}">
            {{ savedSearch.searchName }}
          </h2>
          <v-progress-circular
            v-if="loading"
            class="loading-circle"
            indeterminate
            :size="20"
            :width="3"
          />
        </v-card-title>
        <SearchParameters
          :value="true"
          is-card
          :search-params-obj="searchParamsObj"
          @formatted-filter-params="generateHoverText"
        />

        <div class="footer">
          <div class="results">
            <strong>{{ savedSearch.count }} {{ savedSearch.count !== 1 ? 'results' : 'result' }}</strong>  at last run
          </div>
          <div class="saved">
            Saved {{ moment(savedSearch.updatedAt).format('DD MMM YYYY') }}
          </div>
        </div>
      </div>
    </div>
    <Dialog
      v-model="saveSearchDialog"
      :title="saveUpdateSearchDialogTitle"
      :width="$vuetify.breakpoint.xsOnly ? 360 : 600"
    >
      <template #content>
        <SaveUpdateSearch
          v-model="saveSearchDialog"
          @title="getTitle"
        />
      </template>
    </Dialog>

    <Dialog
      v-model="deleteDialog"
      icon="delete_outline"
      title="Are you sure you want to delete?"
      :width="$vuetify.breakpoint.xsOnly ? 360 : 600"
    >
      <template #content>
        <v-card-text>
          <p>You will not be able to undo these changes.</p>
        </v-card-text>
        <v-card-actions>
          <AdsButton
            secondary
            button-text="Yes"
            @click="handleDelete"
            @keyup.enter="handleDelete"
          />
          <AdsButton
            button-text="No"
            @click="deleteDialog = false"
            @keyup.enter="deleteDialog = false"
          />
        </v-card-actions>
      </template>
    </Dialog>
    <ConfirmSearchPreferenceUpdate
      v-model="showConfirmModal"
      :ai-search-user="isAISearchUser"
      @confirm-preference-update="confirmPreference"
    />
  </v-card>
</template>

<script>
import {AdsButton, FeatureIcon, ADS_Colors} from '@nswdoe/doe-ui-core';
import moment from 'moment';
import Dialog from '@/components/Dialogs/Dialog';
import SaveUpdateSearch from '@/views/SavedSearchesPage/SaveUpdateSearch';
import SearchParameters from '@/views/SavedSearchesPage/SearchParameters';
import {RESOURCE_CATEGORIES} from '@/constants'
import ConfirmSearchPreferenceUpdate from '@/components/Dialogs/ConfirmSearchPreferenceUpdate.vue';
import {mapGetters} from 'vuex';

export default {
  name: 'SavedSearchesCard',
  components: {
    ConfirmSearchPreferenceUpdate,
    SearchParameters,
    SaveUpdateSearch,
    Dialog,
    AdsButton,
    FeatureIcon
  },
  props: {
    savedSearch: {
      type: Object,
      default: () => {
      }
    },
    size: {
      type: [Number, String],
      default: 350
    },
    mobileBreakpoint: {
      type: Number,
      default: 620
    },
    aiSearchUser: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      ADS_Colors,
      moment: moment,
      saveSearchDialog: false,
      deleteDialog: false,
      allFilters: '',
      saveUpdateSearchDialogTitle: 'Update this search',
      loading: false,
      showConfirmModal: false
    }
  },
  computed: {
    ...mapGetters({
      preferences: 'users/preferences'
    }),
    resourceCategory() {
      return this.searchQuery['resource-categories']?.split(',') || []
    },
    resourceCategoryDetails() {
      return this.resourceCategory.map(id => this.getCategoryDetails(id));
    },
    searchParamsObj() {
      if (this.savedSearch.searchParam) {
        let params = this.savedSearch.searchParam.split(' - ');
        let paramsObj = {};
        params.forEach(p => {
          p = p.split('=');
          paramsObj[p[0]] = p[1];
        });
        return paramsObj;
      }
      return {};
    },
    isAISearchUser() {
      return this.preferences?.showNewSearch || false
    },
    isAISearch() {
      let aiSearch = false
      this.savedSearch.searchParam?.split(' - ').forEach(param => {
        const [query, value] = param.split('=');
        if(query === 'ai-input') {
          aiSearch = true;
        }
      });
      return aiSearch
    },
    searchQuery() {
      const allParams = this.savedSearch.searchParam.split(' - ')
      console.log('allParams ', allParams)
      const query = {
        traffic: 'saved-searches'
      }
      for (let param of allParams) {
        console.log(param)
        const [key, value] = param.split('=');
        switch (key) {
          case 'library':
            query.library = value;
            break;
          case 'resource-years':
            query.years = value;
            break;
          case 'resource-klas':
            query.klas = value;
            break;
          case 'resource-types':
            query['resource-type'] = value;
            break;
          case 'resource-sources':
            query.source = value;
            break;
          case 'resource-randntopics':
            if (!query.source.includes('readingandnumeracy'))
              delete query.randntopics;
            else query.randntopics = value;
            break;
          case 'formats':
            query['formats'] = value;
            break;
          case 'resourcetype':
            query['resourcetype'] = value;
            break;
          case 'phase':
            query['phase'] = value;
            break;
          case 'sefalignment':
            query['sefalignment'] = value;
            break;
          case 'focusarea':
            query['focusarea'] = value;
            break;
          case 'resource-categories': // keeping this code so we support old query params
            query['resource-categories'] = value
            break;
          case 'resource-category':
            query['resource-categories'] = value
            break;
          case 'keywords':
            query['search'] = value
            break;
          case 'ai-input':
            query['ai-input'] = value
            break;
        }
      }
      return query;
    },
    hoverText() {
      const hoverTextArr = [];
      hoverTextArr.push(this.savedSearch.searchName);
      this.searchParamsObj.keywords ? hoverTextArr.push(this.searchParamsObj.keywords) : null;
      if (this.allFilters !== '' ) {
        hoverTextArr.push(this.allFilters);
      }
      return hoverTextArr.join(' - ');
    }
  },
  methods: {
    async confirmPreference() {
      // update preferences on confirmation
      // update user preference
      // apply search
      await this.$store.dispatch('users/updatePreferences', { ...this.preferences, showNewSearch : this.isAISearch });
      this.applySearch()
    },
    async checkSearch() {
      // if user preference is not same as the saved search type user preference should be changed
      if (this.isAISearchUser !== this.isAISearch) {
        this.showConfirmModal = true
      } else {
        this.applySearch()
      }
    },
    async applySearch() {
      this.$router.push({
        name: 'HubResourcesPage',
        query: this.searchQuery
      });
    },
    getCategoryDetails(category) {
      return RESOURCE_CATEGORIES.find(cat => cat.id === category) || null;
    },
    handleDelete: async function () {
      const payload = {
        uid: this.$store.state.uid,
        id: String(this.savedSearch._id)
      }
      await this.$store.dispatch('saveSearches/deleteSavedSearchById', payload);
      this.deleteDialog = false;
    },
    handleUpdate: function () {
      this.$store.commit('saveSearches/SET_SAVED_SEARCH', this.savedSearch);
      this.$store.commit('saveSearches/SET_UPDATE_SEARCH_MODE', true);
      this.saveSearchDialog = true;
    },
    getTitle: function (title) {
      this.saveUpdateSearchDialogTitle = title;
    },
    generateHoverText: function (formattedFiltersParams) {
      this.allFilters = formattedFiltersParams.join(', ');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.v-card {
  padding: 10px 20px;
  .header {
    .v-chip {
      border-radius: 5px;
    }
  }
  .footer {
    color: #000;
    font-size: 14px;
    .results {
      margin-bottom: 1px;
    }

    .saved {
      margin-bottom: 0;
      color: #495054;
      font-size: 12px;
    }
  }
}

.v-dialog {
  .v-card__text {
    padding: 24px;
    font-size: 16px;
  }

  .v-card__actions {
    padding: 0 24px 24px 24px;
    display: flex;
    justify-content: flex-end;
  }
}

.primary-control {
  text-decoration: none;
  h2 {
    color: $ads-navy;
    font-size: 20px;
    margin-bottom: 4px;
    white-space: nowrap;
    max-width: 275px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:focus {
    outline-color: $ads-navy;
  }
}


</style>
